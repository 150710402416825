import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { getWindowSize } from "../../utils/dimensions";
import { useDispatch } from "react-redux";
import { logout, logoutUserAsync, updateidebarStatus } from "../../redux/authSlice";
import Horizontal from "../../assets/icons/Horizontal.svg";
import AppIcon from "../../assets/icons/AppIcon.svg";
import "./SidebarMasterAdmin.scss";
import Swal from "sweetalert2";
import Switch from "react-switch";

function SidebarMasterAdmin() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const sidebarOpenRef = useRef(true);
  const [activeListItem, setActiveListItem] = useState("");
  const [checked, setchecked] = useState(false);
  const dispatch = useDispatch();

  const sideBarOptions = [
    {
      name: "Statistics",
      icon: require("../../assets/icons/equalizer.svg").default,
      navigationScreen: "/Statistics",
    },
    {
      name: "Admin Management",
      icon: require("../../assets/icons/account_balance.svg").default,
      navigationScreen: "/AdminManagement",
    },

    {
      name: "Banking Info",
      icon: require("../../assets/icons/account_balance.svg").default,
      navigationScreen: "/BankingInfo",
    },

    {
      name: "Announcement Text",
      icon: require("../../assets/icons/campaign.svg").default,
      navigationScreen: "/Announcement",
    },
    {
      name: "Cat./Sub Categories",
      icon: require("../../assets/icons/category.svg").default,
      navigationScreen: "/Category",
    },
    // {
    //   name: "Messages",
    //   icon: require("../../assets/icons/sms.svg").default,
    //   navigationScreen: "/messages",
    // },
    {
      name: "Terms & Conditions",
      icon: require("../../assets/icons/gavel.svg").default,
      navigationScreen: "/TermsAndConditions",
    },

    {
      name: "Logout",
      icon: require("../../assets/icons/login.svg").default,
      navigationScreen: "",
    },
  ];
  //------
  const url = window.location.href;
  const segments = url.split("/");
  const lastSegment = segments[segments.length - 1];
  //---
  const SetActiveBar = () => {
    setActiveListItem("Statistics");
    const matchingOption = sideBarOptions?.find(
      (option) => option.navigationScreen === `/${lastSegment}`
    );
    if (matchingOption) {
      setActiveListItem(matchingOption.name);
    } else {
      setActiveListItem("Statistics");
    }
  };

  const menuClicked = (name, navigationScreen) => {
    console.log(name);
    let loggesInUser = JSON.parse(sessionStorage.getItem("userLoggedDetails"));
    if (name === "Logout") {
      const data = {
        id: loggesInUser?.id,
        token: loggesInUser?.deviceToken,
        requestType: 0,
        isSwitch: false,
      };
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be logout!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Logout it!",
      }).then((result) => {
        if (result.isConfirmed) {

          dispatch(logoutUserAsync(data));
          // dispatch(logout());
          // sessionStorage.clear();
          // localStorage.clear();
          Swal.fire("Logout", " Successfully Logout", "success");
          return;
        }
      });
    }
    setActiveListItem(name);
    navigate(navigationScreen);
    if (getWindowSize().innerWidth <= 768 && sidebarOpenRef.current) {
      sidebarOpenRef.current = false;
      setOpen(false);
    }
  };
  useEffect(() => {
    SetActiveBar();
    function handleWindowResize() {
      if (getWindowSize().innerWidth <= 768 && sidebarOpenRef.current) {
        sidebarOpenRef.current = false;
        setOpen(false);
      } else if (getWindowSize().innerWidth > 768 && !sidebarOpenRef.current) {
        sidebarOpenRef.current = true;
        setOpen(true);
      }
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [menuClicked]);

useEffect(()=>{
dispatch(updateidebarStatus(open))
},[open])

  return (
    <>
      <div
        className={`sidebar min-h-screen bg-[#38AB7C]
    ${open ? "md:w-[22rem] absolute top-0 left-0 md:static " : "w-[3.2rem]"
          } duration-500 
    `}
      >
        <div className="upper-sidebar flex relative">
          {open ? (
            <>
              <img
                className={`${open && " relative -top-4 mr-5 w-[90%]"}`}
                src={Horizontal}
                alt="ZAIBIDS"
              />
              <FiMenu
                size={26}
                className="cursor-pointer absolute right-2 top-8 text-white"
                onClick={() => setOpen(!open)}
              />
            </>
          ) : (
            <>
              <img
                className={`${!open && " mt-12"}`}
                src={AppIcon}
                alt="Admin"
              />
              <FiMenu
                size={26}
                className="cursor-pointer absolute right-2 top-2 text-white"
                onClick={() => setOpen(!open)}
              />
            </>
          )}
        </div>
        <div
          className={`mb-1 text-white duration-500 flex flex-col justify-center items-center ${!open && "hidden translate-x-28 overflow-hidden"
            }`}
        >
          <img
            src={require("../../assets/icons/admin-pic.svg").default}
            className={`
          duration-500 ${open && "mb-3 h-[3.6rem] w-[3.6rem] rounded-[1.8rem]"
              } ${!open && " mb-4 h-[2rem] w-[2rem] rounded-[1rem]"}`}
            alt="Admin"
          />
          <p className={`mb-1 text-white text-sm`}> Master Admin Panel</p>
        </div>
        <div className="lower-sidebar mt-4">
          {sideBarOptions.map(({ name, icon, navigationScreen }, i) => (
            <div
              key={i}
              className={`sidebar-option cursor-pointer w-full justify-start group flex items-center text-sm gap-3.5 font-medium  ${!open ? "pb-[10px] pt-[10px] pl-3 pr-3" : "p-[8px] pl-4 pr-4"
                } hover:bg-gray-800 ${activeListItem === name ? `bg-gray-800` : null
                }
              `}
              // style={{
              //   backgroundColor: activeListItem === name ? "#0007" : "#0000",
              // }}
              onClick={() => menuClicked(name, navigationScreen)}
            >
              <img
                src={icon}
                className="mr-2 md:mr-4 w-[18px] h-[18px] inline"
                alt={name}
              />
              <span
                style={{
                  transitionDelay: `${i + 3}00ms`,
                }}
                className={`whitespace-pre text-white text-[0.9rem] sidebar-option-text duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"
                  }`}
              >
                {name}
              </span>

              <span
                className={`${open && "hidden"
                  } text-[0.9rem] absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-500 group-hover:w-fit  `}
              >
                {name}
              </span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default SidebarMasterAdmin;
