import React, { useEffect } from "react"; // useEffect
import { BrowserRouter as Router } from "react-router-dom";
import AuthRoutes from "./AuthRoutes";
import AdminRoutes from "./AdminRoutes";
import MasterAdminRoutes from "./MasterAdminRoutes";
import { useSelector } from "react-redux";
// import { emptyAuthState } from "../redux/authSlice"; // Code For Remember Me Functionality
// const dispatch = useDispatch();// Code For Remember Me Functionality

export default function Routes() {
  const {
    userType,
    // userDetails, // Code For Remember Me Functionality
  } = useSelector((state) => state.auth);
  useEffect(() => {
    console.log("useffect", userType);
    // if (window.performance) {
    //   console.info("window performance work on browser");
    // }
    // if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
    //   console.info(
    //     "This page is reloaded",
    //     performance.navigation.TYPE_RELOAD,
    //     PerformanceNavigationTiming
    //   );
    // } else {
    //   if (!userDetails.remember) {
    //     localStorage.clear();
    //     console.log("cleared storage");
    //     dispatch(emptyAuthState());
    //   }
    //   console.info("This page is not reloaded or just called with empty cache");
    // }
  }, []);
  // Code For Remember Me Functionality

  const renderRoutes = () => {
    switch (userType) {
      case "UNREGISTERED":
        return <AuthRoutes />;
      case "admin":
        return <AdminRoutes />;
      case "masteradmin":
        return <MasterAdminRoutes />;
      default:
        break;
    }
  };
  return <Router>{renderRoutes()} </Router>;
}
