import React, { useEffect, useState, useRef } from "react";
import "./NotificationSidebar.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllNotifications,
  UpdateNotifications,
} from "../../redux/chatSlice";
import { UpdateStatusAsync } from "../../redux/usersSlice";
// import avatar from "../../assets/icons/avatar.svg";
// import avatarlight from "../../assets/icons/avatarlight.svg";
import moment from "moment";
import Loader from "../Loader";
import Docs from "../Dialogs/ViewFiles/Docs/Docs";
import { displayImages } from "../CheckImage";
import { formatDate } from "../Utils/formatDate";

const NotificationSidebar = ({
  isNotificationScreenOpen,
  setIsNotificationScreenOpen,
  role,
}) => {
  const dispatch = useDispatch();
  const { notifications, isNotificationLoading, notificationErr } = useSelector(
    (state) => state.chat
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [UserId, setUserID] = useState();
  const [verificationData, setVerificationData] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef(null); 

  // const toggleSidebar = () => {
  //   setIsSidebarOpen(!isSidebarOpen);
  // };

  const MarkAll_AsRead = () => {};
  const gotoNotiScreen = (value) => {
    if (value?.businessUserTbl) {
      setOpenDialog(true);
      setUserID(value?.businessUserTbl?.userId);
      setVerificationData(value?.businessUserTbl?.ShowDocsUrl);
    } else {
      setOpenDialog(true);
      setUserID(value?.customerTbl?.userId);
      setVerificationData(value?.customerTbl?.ShowDocsUrl);
    }

    const data = {
      id: value?.id,
      isRead: true,
      readBy: [role?.id],
    };

    dispatch(UpdateNotifications(data));

    let accType;
    let id;
    if (value?.notificationProvider === 2) {
      accType = "customer";
      id = value?.customerTbl?.id;
    } else if (value?.notificationProvider === 3) {
      accType = "business";
      id = value?.businessUserTbl?.id;
    }

    const rdata = {
      accountType: accType,
      id: id,
      isAdminApproved: true,
      isReview: true,
    };

    dispatch(UpdateStatusAsync(rdata));
  };

  useEffect(() => {
    setIsSidebarOpen(isNotificationScreenOpen);
    dispatch(getAllNotifications());
  }, [isNotificationScreenOpen, dispatch]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Check if the click is outside the sidebar and not on the bell icon
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        !event.target.closest(".header-area")
      ) {
        setIsNotificationScreenOpen(false);
      }
    };

    if (isSidebarOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isSidebarOpen, setIsNotificationScreenOpen]);

  console.log("notifications", notifications);

  const renderNotificationContent = (value, i) => {
    let url = value?.businessUserTbl
      ? value?.businessUserTbl?.image
      : value?.customerTbl?.image;
    // let images;
    let displayImage;
    if (url) {
      // images = checkImage(url);
      displayImage = displayImages(url);
    }
    // const isValidImageUrl = (url) => {
    //   if (!url) return false;
    //   const urlParams = new URLSearchParams(url.split("?")[1]);
    //   const imageName = urlParams.get("imageName");
    //   return imageName && /\.(jpg|jpeg|png)$/i.test(imageName);
    // };

    // // Set displayImage based on the presence of a valid image name in the URL
    // const displayImage = isValidImageUrl(url)
    //   ? url
    //   : "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y";

    moment(parseInt(value?.updatedAt, 10) * 1000).format("LT");

    const timestamp = parseInt(value?.updatedAt, 10) * 1000;
    // const time = moment(timestamp).format("LT");
    const timeInDay = formatDate(timestamp);
    const validImageExtensions = ["jpeg", "jpg", "png"];
    const isValidImage =
      displayImage &&
      validImageExtensions.some((ext) =>
        displayImage.toLowerCase().endsWith(ext)
      );

    const imageSrc = isValidImage
      ? displayImage
      : "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y";

    return (
      <div
        className={`${value.isRead ? "isRead-noti-content" : "noti-content"}`}
        key={i}
      >
        <div className="noti_inner">
          <img
            style={{
              width: "1rem",
              height: "1rem",
              marginRight: "2%",
              borderRadius: "50%",
            }}
            src={
              imageSrc
            }
            onError={(e) => {
              e.target.src =
                "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y";
            }}
            alt="Profile"
          />
          <span
            style={{
              width: "90%",
            }}
          >
            <span style={{ fontWeight: "bold" }}>
              {`${
                value?.businessUserTbl?.userId
                  ? value?.businessUserTbl?.userId
                  : value?.customerTbl?.userId
              } `}
            </span>
            {value.notificationMsg}
          </span>
        </div>
        <div className="footer_notify">
          <span
            style={{
              fontSize: "0.5rem",
              color: "#616b7a !important",
              fontFamily: "roboto !important",
            }}
          >
            {timeInDay}
          </span>
          <span
            style={{
              cursor: "pointer",
              color: value.isRead ? "#9898e3" : "#1d0fff",
              marginLeft: "2px",
              fontSize: "0.6rem",
              textDecoration: "underline",
            }}
            onClick={() => gotoNotiScreen(value)}
          >
            View
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      ref={sidebarRef}
      className={`notification-sidebar ${isSidebarOpen ? "open" : ""}`}
    >
      <div className="header">
        <span className="NotificationsHead"> Notifications </span>
        <span className="MarkAsRead" onClick={() => MarkAll_AsRead()}>
          {" "}
          Mark all as read{" "}
        </span>
      </div>

      <div className="main-notification">
        {isNotificationLoading && <Loader />}

        {!isNotificationLoading &&
        notifications !== undefined &&
        notifications.length > 0 ? (
          notifications.map((value, i) => (
            <>{renderNotificationContent(value, i)}</>
          ))
        ) : (
          <div className="Empty_Container">
            <p style={{ fontSize: "0.9rem" }}>{notificationErr}</p>
          </div>
        )}
      </div>
      <Docs
        verificationData={verificationData}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        UserId={UserId}
      />
    </div>
  );
};

export default NotificationSidebar;
