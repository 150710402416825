import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import { useSelector, useDispatch } from "react-redux";

import "./EmailVerification.scss";
import { updateMAdmin } from "../../../redux/adminManagementSlice";
const EmailVerification = ({ emailOpen, setEmailOpen, role }) => {
  const dispatch = useDispatch();
  const { specialLoginLoading, userDetails } = useSelector(
    (state) => state.auth
  );
  const [newEmail, setNewEmail] = useState("");
  const [currentEmail, setcurrentEmail] = useState("");
  const handleClose = () => {
    setEmailOpen(false);
  };

  const addEmail = () => {
    setEmailOpen(false);
    const data = {
      id: userDetails?.id,
      isShowPrimaryAccount: null,
      currentEmail: currentEmail.trim(),
      newEmail: newEmail.trim(),
    };
    dispatch(updateMAdmin(data));
    setEmailOpen(false);
  };
  return (
    <Dialog open={emailOpen} onClose={handleClose}>
      <div className="EmailVerifyDiv">
        <h1>{role ? "Email for code verification" : "Change Email"}</h1>
        <div className="emailDiv">
          <div className="emails">
            <p>Current Email</p>
            <input
              type="text"
              value={currentEmail}
              onChange={(event) => setcurrentEmail(event.target.value)}
              placeholder="current@example.com"
            />
          </div>
          <div className="emails">
            <p>New Email</p>
            <input
              type="text"
              value={newEmail}
              onChange={(event) => setNewEmail(event.target.value)}
              placeholder="Enter here"
            />
          </div>
        </div>
        <div className="Buttons">
          <button onClick={() => setEmailOpen(false)} className="button1">
            Cancel
          </button>
          <button onClick={() => addEmail()} className="button2">
            Save
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default EmailVerification;
