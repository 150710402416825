import React, { useState } from "react";
import "./AdminManagementCard.scss";
import Eye from "../../assets/icons/eye.svg";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import MenuDialog from "../../components/Dialogs/MenuDialog/MenuDialog";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import { useSelector, useDispatch } from "react-redux";
import Blur from "react-css-blur";
import Switch from "react-switch";

import {
  storeUserId,
  storeId,
  store_Selected_UUID,
  UpdateUser,
  updateMAdmin,
} from "../../redux/adminManagementSlice";
import { regenerateOTPDataAsysnc, resetOtpState } from "../../redux/OTPSlice";
import avatar from "../../assets/icons/avatar.svg";
import { Tooltip } from "react-tooltip";
import { SpecialAdminLogin } from "../../redux/authSlice";
import Load from "../../assets/icons/RippleLoad.svg";
function AdminManagementCard({
  item,
  index,
  Generateotp,
  ChangePass,
  Deleted,
  LoginHis,
  Unsuccessfull,
  setShowMadmin,
  changeEmail,
}) {
  const [showPassword, setshowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [selectedUserOtp, setSelectedUserOtp] = useState(true);

  //Hover pass functionality
  const [showOnHoverPassword, setShowOnHoverPassword] = useState(false);
  const loggesInUser = JSON.parse(sessionStorage.getItem("userLoggedDetails"));

  const dispatch = useDispatch();
  const { selectedEmail } = useSelector((state) => state.adminManage);
  const { userDetails } = useSelector((state) => state.auth);

  let isShow =
    item?.accountType?.toLowerCase() === "masteradmin" &&
    !loggesInUser?.isPrimaryAccount &&
    item?.isPrimaryAccount;

  // useEffect(() => {
  //   if (item) {
  //     setSelectedUserOtp((prevOtp) => ({
  //       ...prevOtp,
  //       [item.userId]: prevOtp[item.isSentOtpAuto],
  //     }));
  //   }
  // }, [item]);

  const handleClose = () => {
    setOpen(false);
  };

  const GeneratedOTP = (item) => {
    dispatch(storeUserId(item.emailAddress));
    dispatch(storeId(item.userId));
    dispatch(store_Selected_UUID(item?.id));
    dispatch(resetOtpState());
  };

  const ShowOTP = async (item) => {
    setOtp("");
    dispatch(storeUserId(item.emailAddress));
    setShowOTP(!showOTP);
    if (!showOTP) {
      setOtpLoading(true);
      try {
        const response = await dispatch(regenerateOTPDataAsysnc(item.userId));
        setOtp(response.otp);
      } catch (error) {
        console.error(error);
      } finally {
        setOtpLoading(false);
      }
    }
  };

  // Auto Login------------
  const OpenAsAdmin2 = async (item) => {
    // var user = {
    //   userId: userDetails.userId,
    //   password: userDetails.password,
    // };
    // var MaDetails = JSON.stringify(user);
    // // Set the user object in localStorage
    // localStorage.setItem("MaUser", MaDetails);
    if (item?.userId && item?.password) {
      const data = {
        userId: item?.userId,
        password: item?.password,
        iPAddress: item?.iPAddress,
        accountType: "master" || "admin",
      };
      try {
        dispatch(SpecialAdminLogin(data, false));
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("Somthing went wrong..");
    }
  };

  // Broken image handling
  const FailedToLoad = (e) => {
    console.log("not opened");
    e.target.src = avatar;
  };

  const Check = (item) => {
    let isTrue;
    if (item?.isSentOtpAuto) {
      isTrue = false;
    } else {
      isTrue = true;
    }
    const data = {
      id: item?.id,
      isSentOtpAuto: isTrue,
    };
    dispatch(updateMAdmin(data));

    // setSelectedUserOtp((prevOtp) => {
    //   const updatedOtp = {
    //     ...prevOtp,
    //     [item?.userId]: !prevOtp[item?.userId],
    //   };
    //   // Save updatedOtp to localStorage
    //   localStorage.setItem("selectedUserOtp", JSON.stringify(updatedOtp));
    //   return updatedOtp;
    // });
    // dispatch(UpdateUser(item?.id,selectedUserOtp[item.userId]))
  };

  return (
    <>
      <div className="cardContainer" key={index}>
        <div className="card__inner-container">
          <div className="badge_container">
            <div className="profile_conateiner">
              <img
                src={item?.image ? "https://" + item?.image : avatar}
                className="userImg"
                onError={FailedToLoad}
              />
            </div>
            <div className="badge_n_more">
              {item?.accountType?.toLowerCase() !== "admin" &&
                !item?.isPrimaryAccount && (
                  <>
                    <span
                      data-tooltip-id="my-tooltip_S"
                      data-tooltip-content={"Secondary"}
                      className="secondAcc"
                    >
                      S
                    </span>
                    <Tooltip
                      id="my-tooltip_S"
                      style={{
                        backgroundColor: "#38ab7c",
                        height: "fit-content",
                        fontSize: "0.6rem",
                      }}
                    />
                  </>
                )}
              <button
                className={
                  item?.accountType?.toLowerCase() === "admin"
                    ? "admin_btn"
                    : "master_btn"
                }
              >
                {item?.accountType?.toLowerCase() === "admin" ? (
                  <p
                    onClick={() => OpenAsAdmin2(item)}
                    className="admin_btn-text-one"
                  >
                    Admin
                  </p>
                ) : (
                  <p className="master_admin_text">Master Admin</p>
                )}
                {item?.accountType?.toLowerCase() === "admin" ? (
                  <span
                    onClick={() => OpenAsAdmin2(item)}
                    className="admin_btn-text-two"
                  >
                    Login
                  </span>
                ) : null}
              </button>
              <MoreVertRoundedIcon
                className="MoreBTN"
                onClick={() => {
                  setOpen(true);
                  GeneratedOTP(item);
                }}
              />
            </div>
          </div>
          <div
            className={
              item?.isLoggedIn ? "user_id_container2" : "user_id_container"
            }
              >
            <div className="StatusContainer">
              <p className="user_id_text">{item?.userId}</p>
            </div>
            {item?.accountType?.toLowerCase() === "admin" && (
              <div className="sentOtpMain">
                <p className="SendText">Send OTP</p>
                <Switch
                  onChange={() => Check(item)}
                  checked={item?.isSentOtpAuto}
                  // uncheckedIcon={false}
                  // checkedIcon={false}
                  onColor="#6ab193" 
                  offColor="#FFD555" 
                  height={18}
                  width={36}
                />
              </div>
            )}
          </div>

          <div className="bottom_Details">
            <div className="Common_data">
              <p className="Headings">Name :</p>
              <p className="admin-name">
                {item?.firstName} {item?.lastName}
              </p>
            </div>
            <div className="Common_data">
              <p className="Headings">Email :</p>
              <p
                data-tooltip-id={`email_${item?.id}`}
                data-tooltip-content={item?.emailAddress}
                className="admin-name"
              >
                {item?.emailAddress}
              </p>
              {item?.emailAddress?.length > 15 && (
                <Tooltip
                  id={`email_${item?.id}`}
                  style={{
                    backgroundColor: "#38ab7c",
                    zIndex: 9999999,
                    fontSize: "0.6rem",
                  }}
                />
              )}
            </div>
            <div className={`${isShow ? "blured" : null}`}>
              <Blur radius={isShow ? "5px" : "0"} transition="400ms">
                <div className="Common_data">
                  <p className="Headings">Pw :</p>
                  <p className="admin-name">
                    <div className="passWordContainer">
                      <div className="password_digit">
                        {showPassword ? (
                          <>
                            <p
                              className="WrittenPassword"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={item?.password}
                            >
                              {item?.password?.length > 5
                                ? item?.password.substring(0, 5) + "..."
                                : item?.password}
                            </p>
                            {item?.password?.length > 5 ? (
                              <Tooltip
                                id="my-tooltip"
                                style={{
                                  backgroundColor: "#38ab7c",
                                  zIndex: 100,
                                }}
                              />
                            ) : null}
                          </>
                        ) : (
                          <p className="noneStar">*****</p>
                        )}
                      </div>
                      <button
                        onClick={() => setshowPassword(!showPassword)}
                        className="eyeButton w-5 ml-1"
                      >
                        <img className="Eye" src={Eye} alt="Eye" />
                      </button>
                    </div>
                    {showOnHoverPassword ? (
                      <span className="HoverPassword_Container">
                        <p className="HoverPassword">{item.password}</p>
                      </span>
                    ) : null}
                  </p>
                </div>
                <div className="Common_data">
                  <p className="Headings">Otp :</p>
                  <p className="admin-name">
                    <div className="OTPContainer">
                      <div className="OTP_digit">
                        {showOTP && selectedEmail === item.emailAddress ? (
                          <p className="WrittenPassword">{otp}</p>
                        ) : (
                          <p className="noneStar">*****</p>
                        )}
                        {otpLoading && selectedEmail === item.emailAddress ? (
                          <img className="Loader" src={Load} alt="Wait" />
                        ) : null}
                      </div>
                      <button
                        onClick={() => ShowOTP(item)}
                        className="eyeButton_OTP w-5 ml-1"
                      >
                        <img
                          className="Eye relative right-0"
                          src={Eye}
                          alt="Eye"
                        />
                      </button>
                    </div>
                  </p>
                </div>
                <div className="Common_data">
                  <p className="Headings">IP :</p>
                  <p className="admin-name">{item?.iPAddress}</p>
                </div>
              </Blur>
            </div>
          </div>
          <MenuDialog
            open={open}
            onClose={handleClose}
            item={item}
            Generateotp={Generateotp}
            ChangePass={ChangePass}
            Deleted={Deleted}
            LoginHis={LoginHis}
            setShowMadmin={setShowMadmin}
            Unsuccessfull={Unsuccessfull}
            changeEmail={changeEmail}
            setOpen={setOpen}
          />
        </div>
      </div>
    </>
  );
}

export default AdminManagementCard;
