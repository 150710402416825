import { Route, Routes, Navigate } from "react-router-dom";

import Login from "../pages/Auth/Login";

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AuthRoutes;
