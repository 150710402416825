import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../helper/axiosInstance";
import { toast } from "react-toastify";
import { Constants } from "../utils/Constants";

const initialState = {
  getAllProjectLoading: false,
  allCompletedProjectFailled: "",
  allProjectData: [],
  allCompletedProjectData: [],
  projectsPaginationLength: 0,
  startedRecords: 1,
  toRecords: 2,
  totalPages: 0,

  totalAdminPage: 0,
  allAdminData: [],
  AdminPaginationDataLength: 0,
  adminStartedRecords: 1,
  adminToRecords: 20,
  //-------
  deleteProjectLoading: false,
  deleteProject: [],
  deleteProjectError: "",
  // temp customer projects

  temp_project_loading: false,
  temp_project_data: [],
  temp_project_failed: "",

  // Selected project
  Selected_Project: [],
  //QnA

  GetQnALoading: false,
  QnAData: [],
  getQnAFailed: "",
  QnADataLength: 0,
  QnAStartedRecords: 1,
  QnAToRecords: 20,
  totalQnAPage: 0,
  // Post answer;

  postAnsLoading: false,
  postAnsFailed: "",

  // fake rate and comment
  RateCommentLoading: false,
  RateCommentData: [],
  RateCommentFailed: "",
  //get all dummy rates
  GetAllRateCommentLoading: false,
  GetAllRateCommentData: [],
  GetAllRateCommentFailed: "",
};
export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    getAllProject: (state) => {
      state.getAllProjectLoading = true;
    },
    getAllProjectSuccess: (state, action) => {
      console.log("payload", action.payload[0]);
      state.getAllProjectLoading = false;
      state.allProjectData = action.payload[0];

      state.projectsPaginationLength = action.payload[1];
      state.startedRecords =
        (action.payload.CurrentPage - 1) * Constants.TAKE + 1;
      state.toRecords = Math.min(
        action.payload.CurrentPage * Constants.TAKE,
        action.payload[1]
      );
      state.totalPages = state.totalPages = Math.ceil(
        action.payload[1] / Constants.TAKE
      );

      // state.allAdminData = action.payload[0];
      // state.AdminPaginationDataLength = action.payload[1];
      // state.adminStartedRecords = (action.payload.CurrentPage - 1) * 2 + 1;
      // state.adminToRecords = action.payload.CurrentPage * 2;
      // state.totalAdminPage = Math.ceil(action.payload[1] / Constants.TAKE);
    },

    getAllProjectFailed: (state) => {
      state.getAllProjectLoading = false;
    },
    getAllCompletedProjectSuccess: (state, action) => {
      state.getAllProjectLoading = false;
      state.allCompletedProjectData = action.payload;
    },
    getAllCompletedProjectFailed: (state) => {
      state.getAllProjectLoading = false;
      state.allCompletedProjectFailled = "Project not Found";
      state.allCompletedProjectData = null;
    },

    deleteProject: (state) => {
      state.deleteProjectLoading = true;
      state.deleteError = "";
    },
    deleteProjectSuccess: (state, action) => {
      state.deleteProjectLoading = false;
      state.temp_project_data = state.temp_project_data.filter(
        (i) => i.id !== action.payload
      );
    },
    deleteProjectFailed: (state, action) => {
      state.deleteProjectLoading = false;
      state.deleteProjectError = action.payload;
    },
    get_all_temp_project: (state) => {
      state.temp_project_loading = true;
    },
    get_all_temp_project_success: (state, action) => {
      state.temp_project_loading = false;
      state.temp_project_data = action.payload;
    },
    get_all_temp_project_failed: (state, action) => {
      state.temp_project_loading = false;
      state.temp_project_failed = action.payload;
    },
    Store_Selected_Project: (state, action) => {
      state.Selected_Project = action.payload;
    },
    //-QnA
    getAllQnA: (state) => {
      state.GetQnALoading = true;
    },
    getAllQnA_success: (state, action) => {
      state.GetQnALoading = false;
      state.QnAData = action.payload[0];
      state.QnADataLength = action.payload[action.payload.length - 1];
      state.QnAStartedRecords =
        (action.payload.CurrentPage - 1) * Constants.TAKE + 1;
      state.QnAToRecords = Math.min(
        action.payload.CurrentPage * Constants.TAKE,
        action.payload[action.payload.length - 1]
      );
      state.totalQnAPage = Math.ceil(
        action.payload[action.payload.length - 1] / Constants.TAKE
      );
    },
    getAllQnA_failed: (state, action) => {
      state.GetQnALoading = false;
      state.getQnAFailed = action.payload;
    },
    // post answer
    PostAnswer: (state) => {
      state.postAnsLoading = true;
    },
    PostAnsweSuccess: (state) => {
      state.postAnsLoading = false;
    },
    PostAnswefailed: (state, action) => {
      state.postAnsLoading = false;
      state.postAnsFailed = action.payload;
    },
    //fake rate and comment
    DummyRateAndComment: (state) => {
      state.RateCommentLoading = true;
    },
    DummyRateAndCommentSuccess: (state, action) => {
      state.RateCommentLoading = false;
      state.GetAllRateCommentData = [
        ...state.GetAllRateCommentData,
        action.payload,
      ];
    },
    DummyRateAndCommentFailed: (state, action) => {
      state.RateCommentLoading = false;
      state.RateCommentFailed = action.payload;
    },
    //Get All fake rate and comment
    GetAllDummyRateAndComment: (state) => {
      state.GetAllRateCommentLoading = true;
    },
    GetAllDummyRateAndCommentSuccess: (state, action) => {
      state.GetAllRateCommentLoading = false;
      state.GetAllRateCommentData = action.payload;
    },
    GetAllDummyRateAndCommentFailed: (state, action) => {
      state.GetAllRateCommentLoading = false;
      state.GetAllRateCommentFailed = action.payload;
    },
  },
});

export const getAllProjectAsync = (data) => (dispatch) => {
  dispatch(getAllProject());
  axiosInstance
    .get("/Category/GetProject", {
      params: {
        projectTitle: data?.projectTitle,
        location: data?.location,
        page: data.page,
        perPage: data.perPage,
      },
    })
    .then((response) => {
      console.log("resp...", response);
      dispatch(getAllProjectSuccess(response?.data));
    })
    .catch((err) => {
      console.log(err);
      dispatch(getAllProjectFailed());
    });
};

export const deleteProjectAsync = (projectId) => async (dispatch) => {
  dispatch(deleteProject());
  try {
    const response = await axiosInstance.delete(
      "/BusisnessUser/DeleteProject",
      {
        params: {
          projectId: projectId,
        },
      }
    );
    if (response) {
      toast.success("Project Deleted !!");
      dispatch(deleteProjectSuccess(projectId));
      return response;
    }
  } catch (err) {
    console.log(err);
    dispatch(deleteProjectFailed(err.response.data));
    return err;
  }
};

export const getAllCompletedProjects = (data) => (dispatch) => {
  dispatch(getAllProject());
  axiosInstance
    .get("/BusisnessUser/GetProjectForHomeScreen", {
      params: {
        CustomerOrBusinessID: data.id,
        requestType: 5,
        accType: 1,
        UserType: data?.UserType,
        page: data?.page,
        perPage: data?.perPage,
      },
    })
    .then((response) => {
      console.log("resp...", response);
      dispatch(getAllCompletedProjectSuccess(response?.data[0]));
    })
    .catch((err) => {
      console.log(err);
      dispatch(getAllCompletedProjectFailed());
    });
};

export const getAll_temp_ProjectAsync = (data) => async (dispatch) => {
  try {
    dispatch(get_all_temp_project());
    const response = await axiosInstance.get("/Login/GetProjectsByCustomerId", {
      params: {
        CustomerId: data?.CustomerId,
        BusinessId: data?.BusinessId,
        status: data?.status,
        page: data?.page,
        perPage: data?.perPage,
        accountType: data?.accountType,
      },
    });
    if (response) {
      console.log("response...", response);
      dispatch(get_all_temp_project_success(response?.data[0]));
    }
  } catch (error) {
    dispatch(get_all_temp_project_failed(error?.response?.data));
  }
};

export const getAllQnAAsync = (projectId, take, CurrentPage) => (dispatch) => {
  dispatch(getAllQnA());
  axiosInstance
    .get("/Category/GetQA", {
      params: {
        projectId: projectId,
        page: CurrentPage,
        perPage: take,
      },
    })
    .then((response) => {
      response.data.CurrentPage = CurrentPage;
      dispatch(getAllQnA_success(response?.data));
    })
    .catch((err) => {
      console.log(err);
      dispatch(getAllQnA_failed());
    });
};

// Post answer;

export const AskQuestionAsync = (data) => async (dispatch) => {
  try {
    dispatch(PostAnswer());
    const response = await axiosInstance.post("/Category/CreateQAPost", data);
    dispatch(PostAnsweSuccess(response?.data));
    toast.success("Response Send !!");
    return response;
  } catch (err) {
    console.error(err);
    dispatch(PostAnswefailed());
    return err;
  }
};

//Dummy rate and comment action --------

export const DummyRateAsync = (data) => async (dispatch) => {
  try {
    dispatch(DummyRateAndComment());
    const response = await axiosInstance.post(
      "/upload/fakeCommentsTempCustomer",
      data
    );
    dispatch(DummyRateAndCommentSuccess(response?.data));
    toast.success("Feedback submited !!");
    return response;
  } catch (err) {
    console.error(err);
    dispatch(DummyRateAndCommentFailed());
    return err;
  }
};

export const GetAllDummyRateAsync = (data) => async (dispatch) => {
  try {
    dispatch(GetAllDummyRateAndComment());
    const response = await axiosInstance.get(
      "/BusisnessUser/getBusinessRating",
      {
        params: {
          accountType: data?.accountType,
          userId: data?.userId,
          page: data?.page,
          perPage: data?.perPage,
        },
      }
    );
    dispatch(GetAllDummyRateAndCommentSuccess(response?.data?.res[0]));
    return response;
  } catch (err) {
    console.error(err);
    dispatch(GetAllDummyRateAndCommentFailed());
    return err;
  }
};
// Action creators are generated for each case reducer function
export const {
  GetAllDummyRateAndComment,
  GetAllDummyRateAndCommentSuccess,
  GetAllDummyRateAndCommentFailed,
  DummyRateAndComment,
  DummyRateAndCommentSuccess,
  DummyRateAndCommentFailed,
  PostAnswer,
  PostAnsweSuccess,
  PostAnswefailed,
  getAllQnA,
  getAllQnA_success,
  getAllQnA_failed,
  getAllProject,
  getAllProjectSuccess,
  getAllProjectFailed,
  deleteProject,
  deleteProjectSuccess,
  deleteProjectFailed,
  getAllCompletedProjectSuccess,
  getAllCompletedProjectFailed,
  get_all_temp_project,
  get_all_temp_project_success,
  get_all_temp_project_failed,
  Store_Selected_Project,
} = projectSlice.actions;

export default projectSlice.reducer;
