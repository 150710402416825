import axios from "axios";
import { ApiConnection } from "../config/env";

let headers = {};

const axiosInstance = axios.create({
  baseURL: ApiConnection.BACKEND_URL,
  headers,
  //   timeout: 5000,
});

// axiosInstance.defaults.timeout = 2000;

axiosInstance.interceptors.request.use(
  async (config) => {
    let ContentType = "";
    if (config?.data?.constructor?.name === "FormData") {
      ContentType = "multipart/form-data";
    } else {
      ContentType = "application/json";
    }
    let token = sessionStorage.getItem("userLoggedToken");
    // console.log("tokennn", JSON.parse(token));
    if (token) {
      config.headers["Authorization"] = "Bearer " + JSON.parse(token);
    }
    config.headers["Content-Type"] = ContentType;
    console.log("Request sent", config);
    return config;
  },
  (error) => {
    console.log("Request sent error", error);

    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    console.log("request end", response);
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log("request  end err", error);

    return Promise.reject(error);
  }
);

export default axiosInstance;
