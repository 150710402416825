import React, { useState, useEffect } from "react";
import "./SearchByProject.scss";
import SearchIcon from "@mui/icons-material/Search";
import Container from "../../../components/HOC/Container";
import Group_345 from "../../../assets/icons/Group_345.svg";
import keyboard_arrow_down from "../../../assets/icons/keyboard_arrow_down.svg";
import calendar_month from "../../../assets/icons/calendar_month.svg";
import { useDispatch, useSelector } from "react-redux";
import { getAllProjectAsync } from "../../../redux/projectSlice";
// import SearchByProjectDIeleteDislog from "../../../components/Dialogs/AdminPanelActionDialog/SearchByProjectDIeleteDislog";
import Loader from "../../../components/Loader";
import NOdataFound from "../../../assets/icons/NoAdminData.svg";
import Footer from "../../../components/Footer/Footer";
import { Constants } from "../../../utils/Constants";
import moment from "moment";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Swal from "sweetalert2";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Checkbox } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import avatar from "../../../assets/icons/avatar.svg";

function SearchByProject() {
  const dispatch = useDispatch();
  const {
    allProjectData,
    getAllProjectLoading,
    deleteProjectLoading,
    projectsPaginationLength,
    startedRecords,
    toRecords,
    totalPages,
    allAdminData,
    totalAdminPage,
    AdminPaginationDataLength,
    adminStartedRecords,
    adminToRecords,
  } = useSelector((state) => state.project);
  const [selectedProjectId, setSelectedProjectedId] = useState("");
  const classes = useStyles();
  const [projectTitle, setProjectTitle] = useState("");
  const [location, setLocation] = useState("");
  const [page, setPage] = useState(1);
  //----- Filters
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState("Project Title");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("Search by");

  const handleSelection = (value) => {
    setFilter(value);
    setSelectedValue(value);
    setIsDropdownOpen(false); // Close the dropdown after selection
  };

  const handleClickOpen1 = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleChangeSelect = (event) => {
    setFilter(event.target.value || "");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseSelect = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };
  //-----
  const handleChange = (event, value) => {
    setPage(value);
    dispatch(getAllProjectAsync(data, Constants.TAKE, value));
  };

  const data = {
    projectTitle,
    location,
    page: 1,
    perPage: Constants.TAKE,
  };
  useEffect(() => {
    dispatch(getAllProjectAsync(data, Constants.TAKE, 1));
  }, [dispatch]);

  const deleteProject = (row) => {
    setSelectedProjectedId(row?.id);
  };
  const SearchQuery = (e) => {
    if (filter === "Location") {
      setLocation(e.target.value);
    } else if (filter === "Project") {
      setProjectTitle(e.target.value);
    } else {
      setProjectTitle(e.target.value);
    }
  };
  const SearchProjectByTitle = () => {
    dispatch(getAllProjectAsync(data, Constants.TAKE, 0, 1));
    setProjectTitle("");
  };
  // No Copy Paste Functionality ------

  function handleCopy(event) {
    event.preventDefault();
    Swal.fire({
      icon: "error",

      text: "Copy / Paste are not allowed  ",
    });
  }

  function handlePaste(event) {
    event.preventDefault();
    Swal.fire({
      icon: "error",

      text: "Copy / Paste are not allowed  ",
    });
  }
  // broken image functionality
  const FailedToLoad = (e) => {
    console.log("not opened");
    e.target.src = avatar;
  };
  return (
    <Container>
      {getAllProjectLoading || deleteProjectLoading ? <Loader /> : null}
      <div
        onCopy={handleCopy}
        onPaste={handlePaste}
        className="containerForSearchByProject "
      >
        {allProjectData?.length === 0 && getAllProjectLoading ? (
          <div className="NoAdminDataForSearchBusAccount">
            <img src={NOdataFound} alt="NO DATA FOUND" />
            <div className="NoAdminDataForSearchBusAccount_Title">
              <h1>No Business Users Available</h1>
              <h3>Currently there are no project avilable.</h3>
            </div>
          </div>
        ) : (
          <>
            <div className="HeadingAndSearchSearchByProject">
              <h1>Search by project number or invoice number</h1>
              <div className="RightBarSearchByProject">
                <p>NO COPY AND PASTE AND NO PRINTING OF THIS SCREEN</p>
                <div className="filters">
                  <div style={{padding:'0.3rem'}}>
                  {/* <button className="locationSearchByProject">
                    Location
                    <img
                      style={{ marginLeft: 5 }}
                      src={keyboard_arrow_down}
                      alt="category"
                    />
                  </button>
                  <button className="filterSearchByProject">
                    From Date
                    <img
                      style={{ marginLeft: 10 }}
                      src={calendar_month}
                      alt="  Sub Category"
                    />
                  </button>
                  <button className="filterSearchByProject">
                    To Date
                    <img
                      style={{ marginLeft: 10 }}
                      src={calendar_month}
                      alt=" Location Filter"
                    />
                  </button> */}
                    <button
                      onClick={handleClickOpen1}
                      className="SearchSearchByProject"
                    >
                      {selectedValue}
                      <img
                        style={{ marginLeft: 5 }}
                        src={keyboard_arrow_down}
                        alt="Search by"
                      />
                    </button>

                    {isDropdownOpen && (
                      <div className="dropdown-content3">
                        <ul>
                          <li onClick={() => handleSelection("Location")}>
                            Location
                          </li>
                          <li onClick={() => handleSelection("Project Title")}>
                            Project Title
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>

                  <div className="inputDivSearchByProject">
                    <SearchIcon
                      onClick={SearchProjectByTitle}
                      className="searchIconSearchByProject"
                      style={{ color: "#777", fontSize: 22 }}
                    />
                    <input
                      onChange={SearchQuery}
                      onKeyDown={(event) => {
                        event.key === "Enter" && SearchProjectByTitle();
                      }}
                      className="input-fieldSearchByProject"
                      type="text"
                      placeholder={`Search ${filter}`}
                    />
                  </div>
                </div>
              </div>
            </div>
            <TableContainer
              sx={{
                height: "calc( 100% - 90px)",
                width: "100%",
                overflowX: "auto",
              }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className={classes.root}>
                    <TableCell>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        {/* <Checkbox checked={checked1}
                              onChange={toggleSelectAll} /> */}
                        <p>Username</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Email Address</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Location</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>

                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Category</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Sub-Category</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Project/Invoice</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Pay Date</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Pay Type</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Service Provided</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Payment Gateway</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Due Date</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allProjectData?.map((item, i) => {
                    return (
                      <TableRow
                        className={classes.bodyroot}
                        hover
                        role="checkbox"
                      >
                        <TableCell key={i}>
                          <span
                            // onClick={() => NavigateToBusinessUserProfile(item)}
                            className="flex justify-start items-center "
                          >
                            {/* <Checkbox
                                    checked={checkboxStates[item?.userId] || checked1}
                                    onChange={() => toggleCheckbox(item, i)} /> */}
                            <div
                              style={{
                                width: "2.1rem",
                                height: "2.1rem",
                                borderRadius: "50%",
                                border: "1px solid grey",
                                // cursor: 'pointer'
                              }}
                            >
                              <img
                                style={{
                                  width: "2rem",
                                  height: "2rem",
                                  borderRadius: "50%",
                                  margin: " 0 auto",
                                }}
                                onError={FailedToLoad}
                                src={
                                  item.customer?.image &&
                                  (item.customer?.image.includes(".jpg") ||
                                    item.customer?.image.includes(".jpeg") ||
                                    item.customer?.image.includes(".png"))
                                    ? `https://${item?.customer?.image}`
                                    : avatar
                                }
                                alt="user"
                                // onClick={() => NavigateToProfile(item?.data)}
                              />
                            </div>
                            <span className="returingDataSearch ml-2">
                              {item?.customer?.name}
                            </span>
                          </span>
                        </TableCell>
                        <TableCell>
                          <p className="returingDataSearch">
                            {item?.customer?.emailAddress}
                          </p>
                        </TableCell>
                        <TableCell>
                          {item?.location?.map((P_loc) => (
                            <p className="returingDataSearch">{P_loc}</p>
                          ))}
                        </TableCell>
                        <TableCell>
                          <p className="returingDataSearch">
                            {item?.category?.name}
                          </p>
                        </TableCell>
                        <TableCell>
                          <p className="returingDataSearch">
                            {item?.subCategoryTbl?.name}
                          </p>
                        </TableCell>
                        <TableCell>
                          <p className="returingDataSearch">
                            {item?.projectTitle}
                          </p>
                        </TableCell>
                        <TableCell>
                          <p className="returingDataSearch">12 sep 2023</p>
                        </TableCell>
                        <TableCell>
                          <p className="returingDataSearch">Card</p>
                        </TableCell>
                        <TableCell>
                          <p className="returingDataSearch">Development</p>
                        </TableCell>
                        <TableCell>
                          <p className="returingDataSearch">Merchant</p>
                        </TableCell>
                        <TableCell>
                          <p className="returingDataSearch">15 Dec 2023</p>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </div>
      {projectsPaginationLength > Constants.TAKE ? (
        <Footer
          page={page}
          handleChange={handleChange}
          totalRecord={projectsPaginationLength}
          startedRecords={startedRecords}
          toRecords={toRecords}
          totalPages={totalPages}
        />
      ) : null}
    </Container>
  );
}
export default SearchByProject;
const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      height: "28px",
      padding: "0.3rem 1rem",
    },
  },
  bodyroot: {
    "&:hover": {
      backgroundColor: "#c5d9cf !important",
    },
    "& .MuiTableCell-body": {
      height: "28px",
      padding: "0.2rem 1rem",
    },
  },
});
