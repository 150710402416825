import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../helper/axiosInstance";
import Swal from "sweetalert2";
import { Constants } from "../utils/Constants";
import { toast } from "react-toastify";

const initialState = {
  //for messages
  selectedOption: [{ label: "Select message", value: "" }],
  selectedOptions: [],
  //------------Search Bussiness-------------------
  searchBussinessLoading: false,
  searchBussinessData: [],
  PaginationDataLength: 0,
  startedRecords: 1,
  toRecords: 5,
  totalPages: 0,
  getUsersErr: "",

  totalAdminPage: 0,
  allAdminData: [],
  AdminPaginationDataLength: 0,
  adminStartedRecords: 1,
  adminToRecords: 20,

  //--------New Bussiness License Verification-----------------
  newBussinessLicenseVerifactionLoading: false,
  newBussinessLicenseVerifactionData: [],
  newBussinessLicenseVerifactionErr: "",

  //--------Pro Bussiness  users-----------------
  proBusinessLoading: false,
  proBusinessData: [],
  proBusinessErr: "",
  //----------Search Customer---------------
  searchCustomerAccountLoading: false,
  searchCustomerAccountData: [],
  searchCustomerAccountErr: "",
  //----------New Customer Account Verification----------------
  newCustomerAccVerificationLoading: false,
  newCustomerAccVerificationData: [],
  newCustomerAccVerificationErr: "",

  //----------Update Customer---------------
  updateCustomerLoading: false,
  updateCustomerData: [],
  updateCustomerErr: "",
  //----------Update Business----------------
  updateBusinessLoading: false,
  updateBusinessData: [],
  updateBusinessErr: "",
  //----------Temp Customer---------------
  tempCustomerAccountLoading: false,
  tempCustomerAccountData: [],

  tempCustomerAccountErr: "",
  tempDeleteUserFailed: "",
  tempDeleteStatus: [],

  //----------UserDetails-----------------
  userDetails: [],
  selectedAccountType: "",
  //----------Create Temp Customer---------------
  createCustomerLoading: false,
  createTempCustomerAccountData: [],
  createCustomerErr: "",
  //----------Create Temp Business----------------
  createBusinessUserLoading: false,
  createTempBusinessAccountData: [],
  createBusinessErr: "",
  //------Temp-Business-Data-------
  tempBusinessDataLoading: false,
  tempBusinessAccountData: [],
  tempBusinessDataError: "",
  //------Delete Search Bussiness Account --------------
  deleteBusinessLoading: false,
  searchBusinessAccountData: [],

  // update user status by id ( Mark as verified )
  markAsVerifiedLoading: false,
  MarkAsVerifiedData: [],
  MarkAsVerifiedError: "",

  // update password for temp customer

  updateTempLoading: false,
  updateTempPassData: "",
  updateFailer: "",

  // Update Profile

  updateProfileLoading: false,
  updateProfileData: [],
  updateprofileError: "",
  // States for filter statics---
  selectedLocationForFilter: "",
  selectedFromDateForFilter: "",
  selectedToDateForFilter: "",
  // Send message
  sendMessageLoading: false,
  sendMessageData: "",
  sendMessageFailed: "",
  // updating Message status
  UpdateMessageLoading: false,
  Updated_message_data: [],
  Updated_message_failed: "",
  // Add new msg
  Add_msg_Loading: false,
  Add_msg_data: [],
  Add_msg_failed: "",
  // Edit msg
  Edit_msg_Loading: false,
  Edit_msg_data: [],
  Edit_msg_failed: "",
};

export const getUsersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setSelectedOption: (state, action) => {
      state.selectedOption = action.payload;
    },
    setSelectedOptions: (state, action) => {
      state.selectedOptions = action.payload;
    },
    //pagination is here
    // getAllAdminSuccess: (state, action) => {
    //   state.getAllAdminLoading = false;
    //   state.allAdminData = action.payload[0];
    //   state.AdminPaginationDataLength = action.payload[1];
    //   state.adminStartedRecords = (action.payload.CurrentPage - 1) * 2 + 1;
    //   state.adminToRecords = action.payload.CurrentPage * 2;
    //   state.totalAdminPage = Math.ceil(action.payload[1] / Constants.TAKE);
    // },
    //==========Search Bussiness ==============================
    getAllsearchBussiness: (state) => {
      state.searchBussinessLoading = true;
    },
    getAllsearchBussinessSuccess: (state, action) => {
      state.searchBussinessLoading = false;
      state.searchBussinessData = action.payload[0].filter((Data) => {
        return Data.demo === false && Data?.isAdminApproved === true;
      });
      // state.searchBussinessData = action.payload[0];
      state.PaginationDataLength = action.payload[1];
      state.startedRecords =
        (action.payload.CurrentPage - 1) * Constants.TAKE + 1;
      state.toRecords = Math.min(
        action.payload.CurrentPage * Constants.TAKE,
        action.payload[1]
      );

      state.totalPages = Math.ceil(action.payload[1] / Constants.TAKE);
    },
    getAllsearchBussinessFailed: (state, action) => {
      state.searchBussinessLoading = false;
      state.getUsersErr = "Unable to get business";
    },
    //===============New Bussiness Account Verification ============
    getAllBussinessLicenseVerification: (state) => {
      state.newBussinessLicenseVerifactionLoading = true;
    },
    getAllBussinessLicenseVerificationData: (state, action) => {
      console.log("datttttt", action.payload);
      state.newBussinessLicenseVerifactionLoading = false;

      // state.newBussinessLicenseVerifactionData = action.payload[0].filter(
      //   function (Data) {
      //     return Data.isAdminApproved === false;
      //   }
      // );
      state.newBussinessLicenseVerifactionData = action.payload[0];
      state.PaginationDataLength = action.payload[1];
      state.startedRecords =
        (action.payload.CurrentPage - 1) * Constants.TAKE + 1;
      state.toRecords = Math.min(
        action.payload.CurrentPage * Constants.TAKE,
        action.payload[1]
      );
      state.totalPages = state.totalPages = Math.ceil(
        action.payload[1] / Constants.TAKE
      );
    },
    getAllBussinessLicenseVerification_filtered_Data: (state, action) => {
      state.newBussinessLicenseVerifactionLoading = false;
      state.newBussinessLicenseVerifactionData = action.payload[0].filter(
        function (Data) {
          return Data?.userToCustomiseMsgTblIdTbl !== null;
        }
      );
      // state.newBussinessLicenseVerifactionData = action.payload[0];
      state.PaginationDataLength = action.payload[1];
      state.startedRecords =
        (action.payload.CurrentPage - 1) * Constants.TAKE + 1;
      state.toRecords = Math.min(
        action.payload.CurrentPage * Constants.TAKE,
        action.payload[1]
      );
      state.totalPages = state.totalPages = Math.ceil(
        action.payload[1] / Constants.TAKE
      );
    },
    getAllBussinessLicenseVerificationFailed: (state, action) => {
      state.newBussinessLicenseVerifactionLoading = false;
      state.newBussinessLicenseVerifactionErr = "Unable to get business";
    },

    //==================Search Customer==========================
    getAllsearchCustomer: (state) => {
      state.searchCustomerAccountLoading = true;
    },
    getAllsearchCustomerSuccess: (state, action) => {
      console.log("action: ", action.payload);
      state.searchCustomerAccountLoading = false;
      state.searchCustomerAccountData = action.payload[0];

      // state.searchCustomerAccountData = action.payload?.filter(function (Data) {
      //   return (
      //     Data?.data?.tempAccount === 0 && Data?.data?.isAdminApproved === 1
      //   );
      // });
      state.PaginationDataLength = action.payload[1];
      state.startedRecords =
        (action.payload.CurrentPage - 1) * Constants.TAKE + 1;
      state.toRecords = Math.min(
        action.payload.CurrentPage * Constants.TAKE,
        action.payload[1]
      );
      state.totalPages = state.totalPages = Math.ceil(
        action.payload[1] / Constants.TAKE
      );
    },
    getAllsearchCustomerFailed: (state, action) => {
      state.searchCustomerAccountLoading = false;
      state.searchCustomerAccountErr = "Unable to get customer";
    },

    //=================New Customer Account Verification====================
    getAllnewAccountVerifation: (state) => {
      state.newCustomerAccVerificationLoading = true;
    },
    getAllnewAccountVerifationSuccess: (state, action) => {
      state.newCustomerAccVerificationLoading = false;
      if (action.payload?.item) {
        state.newCustomerAccVerificationData = (
          action.payload?.item[0] || []
        ).filter((Data) => {
          console.log("Data....", Data);
          return Data?.isReview === false;
        });
      } else {
        state.newCustomerAccVerificationData = action.payload[0];
      }

      state.PaginationDataLength = action.payload[1];
      state.startedRecords =
        (action.payload.CurrentPage - 1) * Constants.TAKE + 1;
      state.toRecords = Math.min(
        action.payload.CurrentPage * Constants.TAKE,
        action.payload[1]
      );
      state.totalPages = Math.ceil(action.payload[1] / Constants.TAKE);
    },
    getAllnewAccountVerifationFailed: (state) => {
      state.newCustomerAccVerificationLoading = false;
      state.newCustomerAccVerificationErr = "Unable to get customer";
    },

    //==================Temp Customer==========================
    getAllTempCustomer: (state) => {
      state.tempCustomerAccountLoading = true;
    },
    getAllTempCustomerSuccess: (state, action) => {
      state.tempCustomerAccountLoading = false;
      state.tempCustomerAccountData = action.payload[0];
      state.searchCustomerAccountLoading = false;
      state.PaginationDataLength = action.payload[1];
      state.startedRecords =
        (action.payload.CurrentPage - 1) * Constants.TAKE + 1;
      state.toRecords = Math.min(
        action.payload.CurrentPage * Constants.TAKE,
        action.payload[1]
      );
      state.totalPages = Math.ceil(action.payload[1] / Constants.TAKE);
    },
    getAllMessageSuccess: (state, action) => {
      state.updateMessage = action.payload;
    },
    //-----------------------------------------------------------------------------------------------------
    getAllTempCustomerFailed: (state) => {
      state.tempCustomerAccountLoading = false;
      state.tempCustomerAccountErr = "Unable to get customer";
    },

    //==================Temp Customer Create==========================
    createCustomer: (state) => {
      state.createCustomerLoading = true;
    },
    createCustomerSuccess: (state, action) => {
      // console.log(action.payload);
      // state.createTempCustomerAccountData = action.payload;
      state.createCustomerLoading = false;
      // state.tempCustomerAccountData = [
      //   ...state.tempCustomerAccountData,
      //   action.payload,
      // ];
    },
    createCustomerFailed: (state) => {
      state.createCustomerLoading = false;
      state.createCustomerErr = "Unable to create customer";
    },

    //==================Temp Business==========================
    getAllTempBusiness: (state) => {
      state.tempBusinessDataLoading = true;
    },
    getAllTempBusinessSuccess: (state, action) => {
      state.tempBusinessDataLoading = false;
      state.tempBusinessAccountData = action.payload[0];
      state.PaginationDataLength = action.payload[1];
      state.startedRecords =
        (action.payload.CurrentPage - 1) * Constants.TAKE + 1;
      state.toRecords = Math.min(
        action.payload.CurrentPage * Constants.TAKE,
        action.payload[1]
      );
      state.totalPages = Math.ceil(action.payload[1] / Constants.TAKE);
    },

    getAllTempBusinessFailed: (state) => {
      state.tempBusinessDataLoading = false;
      state.createBusinessErr = "Unable to create business";
    },
    //==================Temp Business Create==========================
    createBusiness: (state) => {
      state.createBusinessUserLoading = true;
    },
    createBusinessSuccess: (state, action) => {
      state.createBusinessUserLoading = false;
      console.log("Business creates response ,,,,", action.payload);
      state.createTempBusinessAccountData = action.payload;
    },
    createBusinessFailed: (state) => {
      state.createBusinessUserLoading = false;
      state.tempCustomerAccountErr = "Unable to get customer";
    },

    //==================Pro Business==========================
    getAllProBusiness: (state) => {
      state.proBusinessLoading = true;
    },
    getAllProBusinessFailed: (state) => {
      state.proBusinessLoading = false;
      state.proBusinessErr = "unable to get pro business user";
    },
    getAllProBusinessSuccess: (state, action) => {
      state.proBusinessLoading = false;
      state.proBusinessData = action.payload[0].filter(function (Item) {
        return Item.status === true;
      });
      state.PaginationDataLength = action.payload[1];
      state.startedRecords =
        (action.payload.CurrentPage - 1) * Constants.TAKE + 1;
      state.toRecords = Math.min(
        action.payload.CurrentPage * Constants.TAKE,
        action.payload[1]
      );
      state.totalPages = state.totalPages = Math.ceil(
        action.payload[1] / Constants.TAKE
      );
    },

    //==================Update Customer==========================
    updateCustomer: (state, action) => {
      for (
        let index = 0;
        index < state.newCustomerAccVerificationData.length;
        index++
      ) {
        if (
          action.payload ===
          state.newCustomerAccVerificationData[index].emailAddress
        ) {
          console.log("Customer found");
          state.newCustomerAccVerificationData[index].isAdminApproved = true;
        }
      }
      state.updateCustomerLoading = true;
      state.newCustomerAccVerificationData = [
        ...state.newCustomerAccVerificationData,
      ];
    },
    updateCustomerSuccess: (state, action) => {
      state.updateCustomerLoading = false;
      state.updateCustomerData = action.payload;
      state.newCustomerAccVerificationData =
        state.newCustomerAccVerificationData.filter(
          (i) => i.emailAddress !== action.payload
        );
    },
    updateCustomerFailed: (state) => {
      state.updateCustomerLoading = false;
      state.updateCustomerErr = "Unable to update customer";
    },

    //=================Update Business====================
    updateBusiness: (state, action) => {
      for (
        let index = 0;
        index < state.newBussinessLicenseVerifactionData.length;
        index++
      ) {
        if (
          action.payload ===
          state.newBussinessLicenseVerifactionData[index].emailAddress
        ) {
          console.log("Business found");
          state.newBussinessLicenseVerifactionData[
            index
          ].isAdminApproved = true;
        }
      }
      state.updateBusinessLoading = true;

      if (action.payload.fromNotification) {
        state.updateBusinessLoading = false;
      } else {
        state.updateBusinessLoading = true;
      }

      state.newBussinessLicenseVerifactionData = [
        ...state.newBussinessLicenseVerifactionData,
      ];
    },
    updateBusinessSuccess: (state, action) => {
      state.updateBusinessLoading = false;
      state.updateBusinessData = action.payload;
      if (action.payload.PrevVeried === true) {
        state.searchBussinessData = state.searchBussinessData?.filter(
          (i) => i?.id !== action.payload?.id
        );
      } else {
        state.newBussinessLicenseVerifactionData =
          state.newBussinessLicenseVerifactionData?.filter(
            (i) => i?.id !== action.payload?.id
          );
      }
      // state.newBussinessLicenseVerifactionData =
      //   state.newBussinessLicenseVerifactionData.filter(
      //     (i) => i.id !== action.payload
      //   );
    },

    updateBusinessReviewSuccess: (state, action) => {
      state.updateBusinessLoading = false;
      state.updateBusinessData = action.payload;
      state.newBussinessLicenseVerifactionData.isReview =
        action.payload.isReview;
    },

    updateBusinessFailed: (state, action) => {
      state.updateBusinessLoading = false;
      state.updateBusinessErr = "Unable to update business";
    },

    //Delete Customer
    deleteCustomer: (state) => {
      state.searchCustomerAccountLoading = true;
      // state.deleteCustomerError = "";
      state.newCustomerAccVerificationLoading = true;
    },
    deleteCustomerSuccess: (state, action) => {
      state.searchCustomerAccountLoading = false;
      state.newCustomerAccVerificationLoading = false;
      state.searchCustomerAccountData = state.searchCustomerAccountData.filter(
        (i) => i.id !== action.payload
      );
      state.newCustomerAccVerificationData =
        state.newCustomerAccVerificationData.filter(
          (i) => i.id !== action.payload
        );
      state.tempCustomerAccountData = state.tempCustomerAccountData.filter(
        (i) => i.id !== action.payload
      );
    },
    deleteCustomerFailed: (state, action) => {
      state.searchCustomerAccountLoading = false;
      state.newCustomerAccVerificationLoading = false;
      state.deleteCustomerError = action.payload;
    },

    //Delete Business
    deleteBusiness: (state) => {
      state.deleteBusinessLoading = true;
      state.deleteBusinessError = "";
    },
    deleteBusinessSuccess: (state, action) => {
      state.deleteBusinessLoading = false;
      state.searchBussinessData = state.searchBussinessData.filter(
        (i) => i.id !== action.payload
      );
      state.newBussinessLicenseVerifactionData =
        state.newBussinessLicenseVerifactionData.filter(
          (i) => i.id !== action.payload
        );
      state.tempBusinessAccountData = state.tempBusinessAccountData.filter(
        (i) => i?.id !== action.payload
      );
    },
    deleteBusinessFailed: (state, action) => {
      state.deleteBusinessLoading = false;
      state.deleteBusinessError = action.payload;
    },
    deleteTempUser: (state) => {
      state.tempCustomerAccountLoading = true;
    },
    deleteTempStatus: (state, action) => {
      state.tempCustomerAccountLoading = false;
      state.tempDeleteStatus = action.payload;
    },
    deleteTempUserSuccess: (state, action) => {
      state.tempCustomerAccountLoading = false;
      state.tempCustomerAccountData = state.tempCustomerAccountData.filter(
        (i) => i.id !== action.payload
      );
    },
    deleteTempUserFailed: (state) => {
      state.tempCustomerAccountLoading = false;
      state.tempDeleteUserFailed = "Unable to delete the user";
    },
    selectedUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    UserAccountType: (state, action) => {
      state.selectedAccountType = action.payload;
    },
    signUpTemporaryAccount: (state) => { },

    VerifyUser: (state) => {
      state.newCustomerAccVerificationLoading = true;
    },
    VerifyBusinessUser: (state) => {
      state.updateBusinessLoading = true;
    },
    VerifyUserSuccess: (state, action) => {
      state.newCustomerAccVerificationLoading = false;
      if (action.payload.PrevVeried === true) {
        state.searchCustomerAccountData =
          state.searchCustomerAccountData?.filter(
            (i) => i?.id !== action.payload?.id
          );
      } else {
        state.newCustomerAccVerificationData =
          state.newCustomerAccVerificationData?.filter(
            (i) => i?.id !== action.payload?.id
          );
      }
    },

    VerifyUserReviewSuccess: (state, action) => {
      state.newCustomerAccVerificationLoading = false;
      state.newCustomerAccVerificationData.isReview = action.payload.isReview;
    },

    VerifyUserFailed: (state) => {
      state.newCustomerAccVerificationLoading = false;
      state.MarkAsVerifiedData = "Unable to verify user";
    },

    UpdateTempPass: (state) => {
      state.updateTempLoading = true;
    },
    UpdateTempPassSuccess: (state, action) => {
      state.updateTempLoading = false;
      state.updateTempPassData = action.payload;
    },
    UpdateTempPassFailed: (state) => {
      state.updateTempLoading = false;
      state.updateFailer = "somthing went wrong ";
    },

    // Update profile custome and business
    UpdateTheProfile: (state) => {
      state.updateProfileLoading = true;
    },
    UpdateProfileSuccess: (state, action) => {
      state.updateProfileLoading = false;
      state.updateProfileData = action.payload;
    },
    UpdateProfileFailed: (state, action) => {
      state.updateProfileLoading = false;
      state.updateprofileError = action.payload;
    },
    /////----Location Select
    SelectedFilterLocation: (state, action) => {
      console.log("actin ??????", action.payload);
      state.selectedLocationForFilter = action.payload;
    },
    SelectedFilterFromDate: (state, action) => {
      state.selectedFromDateForFilter = action.payload;
    },
    SelectedFilterToDate: (state, action) => {
      state.selectedToDateForFilter = action.payload;
    },
    Send_Message: (state) => {
      state.sendMessageLoading = true;
    },
    Send_Message_Success: (state, action) => {
      state.sendMessageLoading = false;
      state.sendMessageData = action.payload;
    },
    Send_Message_Failed: (state, action) => {
      state.sendMessageLoading = false;
      state.sendMessageFailed = action.payload;
    },

    // Update message status:
    Update_messsage_Status: (state) => {
      state.UpdateMessageLoading = true;
    },
    Update_messsage_Status_Success: (state, action) => {
      state.UpdateMessageLoading = false;
      state.Updated_message_data = action.payload;
    },
    Update_messsage_Status_Failed: (state, action) => {
      state.UpdateMessageLoading = false;
      state.Updated_message_failed = action.payload;
    },
    // Add new message :
    AddNewMsg: (state) => {
      state.Add_msg_Loading = true;
    },
    AddNewMsgSuccess: (state, action) => {
      state.Add_msg_Loading = false;
      state.updateMessage = [...state.updateMessage, action.payload];
    },
    AddNewMsgFailed: (state, action) => {
      state.Add_msg_Loading = false;
      state.Add_msg_failed = action.payload;
    },
    // Update message :
    EditMsg: (state) => {
      state.Edit_msg_Loading = true;
    },
    EditMsgSuccess: (state, action) => {
      state.Edit_msg_Loading = false;
      state.Edit_msg_data = action.payload;
    },
    EditMsgFailed: (state, action) => {
      state.Edit_msg_Loading = false;
      state.Edit_msg_failed = action.payload;
    },
    
    UpdateIsLastMsgRead: (state, action) => {
      const data = action.payload?.chatUser;
      console.log("IMTHE", data);

      if (
        data.accountType === "customer" &&
        (data?.isAdminApproved === true || data?.isAdminApproved === 1)
      ) {
        console.log("TsearchCustomerAccountData", data.userId);
        state.searchCustomerAccountData = state.searchCustomerAccountData?.map(
          (user) => {
            if (user?.id === data?.id) {
              console.log("IsMAthced", user?.userId);
              return {
                ...user,
                isLastMsgRead: false,
              };
            }
            return user;
          }
        );
      } else if (
        data.accountType === "customer" &&
        (data?.isAdminApproved === false || data?.isAdminApproved === 0)
      ) {
        console.log(
          "TnewCustomerAccVerificationData??????????????????????",
          data.userId
        );
        state.newCustomerAccVerificationData =
          state.newCustomerAccVerificationData?.map((user) => {
            if (user?.id === data?.id) {
              console.log("IsMAthced??????????????????", user?.userId);
              return {
                ...user,
                isLastMsgRead: false,
              };
            }
            return user;
          });
      } else if (
        data.accountType === "business" &&
        (data?.isAdminApproved === true || data?.isAdminApproved === 1)
      ) {
        console.log("TsearchBussinessData", data.userId);
        state.searchBussinessData = state.searchBussinessData?.map((user) => {
          if (user?.id === data?.id) {
            console.log("IsMAthced", user?.userId);
            return {
              ...user,
              isLastMsgRead: false,
            };
          }
          return user;
        });
      } else if (
        data.accountType === "business" &&
        (data?.isAdminApproved === false || data?.isAdminApproved === 0)
      ) {
        console.log("TnewBussinessLicenseVerifactionData", data.userId);
        state.newBussinessLicenseVerifactionData =
          state.newBussinessLicenseVerifactionData?.map((user) => {
            if (user?.id === data?.id) {
              console.log("IsMAthced", user?.userId);
              return {
                ...user,
                isLastMsgRead: false,
              };
            }
            return user;
          });
      } else if (
        data.accountType === "business" &&
        (data?.isAdminApproved === true || data?.isAdminApproved === 1) &&
        (data.status === true || data.status === 1)
      ) {
        console.log("TproBusinessData", data.userId);

        state.proBusinessData = state.proBusinessData?.map((user) => {
          if (user?.id === data?.id) {
            console.log("IsMAthced", user?.userId);
            return {
              ...user,
              isLastMsgRead: false,
            };
          }
          return user;
        });
      }
    },
  },
});

export const UpdateUserForChat = (data) => (dispatch) => {
  console.log("UpdateUserForChat", data);
  dispatch(UpdateIsLastMsgRead(data));
};

//Get Businesses Action
export const getSearchBusinessAsync = (data) => (dispatch) => {
  if (data?.demo === 1) {
    dispatch(getAllTempBusiness());
  } else if (data?.isAdminApproved === 1) {
    dispatch(getAllsearchBussiness());
    dispatch(getAllProBusiness());
  } else {
    dispatch(getAllBussinessLicenseVerification());
  }
  axiosInstance
    .get("/BusisnessUser/GetBuisnessUserForAdmin", {
      params: {
        location: data.location,
        Email: data.email,
        address: data.address,
        isAdminApproved: data.isAdminApproved,
        businessName: data?.businessName,
        demo: data?.demo,
        page: data.page,
        perPage: data.perPage,
        status: data?.status,
      },
    })
    .then((response) => {
      response.data.CurrentPage = data.page;
      if (response?.status === 200 || 201) {
        console.log("ResR", response);
        if (data?.demo === 1) {
          dispatch(getAllTempBusinessSuccess(response?.data));
        } else if (data?.isAdminApproved === 1) {
          dispatch(getAllProBusinessSuccess(response?.data));
          dispatch(getAllsearchBussinessSuccess(response?.data));
        } else if (data?.isAdminApproved === 0) {
          if (data?.sendMessages === true) {
            dispatch(
              getAllBussinessLicenseVerification_filtered_Data(response?.data)
            );
          } else {
            dispatch(getAllBussinessLicenseVerificationData(response?.data));
          }
        }
      }
    })
    .catch((err) => {
      if (err) {
        dispatch(getAllsearchBussinessFailed());
        dispatch(getAllProBusinessFailed());
        dispatch(getAllBussinessLicenseVerificationFailed());
      }
    });
};

//Update Business Action
export const updateBusinessAsync =
  (userId, isAdminApproved, isReview, fromNotification) => (dispatch) => {
    let data = { isAdminApproved, userId, fromNotification, isReview };
    if (isAdminApproved) {
      dispatch(updateBusiness(data));
    }
    axiosInstance
      .post("BusisnessUser/UpdateBusinessUserTblProfile", {
        userId,
        isAdminApproved,
        isReview,
      })
      .then((response) => {
        console.log(response);
        if (isAdminApproved) {
          dispatch(updateBusinessSuccess(userId));
        } else {
          let data = {
            userId,
            isReview,
          };
          dispatch(updateBusinessReviewSuccess(data));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateBusinessFailed());
      });
  };

export const sendMessageToUser = (Data) => async (dispatch) => {
  dispatch(Send_Message());
  try {
    const response = await axiosInstance.post(
      "Login/saveIdsOfUser&CustomMsgTbl",
      Data
    );
    dispatch(Send_Message_Success(response?.data));
    return response;
  } catch (err) {
    dispatch(Send_Message_Failed(err?.response));
    console.log(err);
    return err;
  }
};

//add message action
export const addMessages = () => (dispatch) => {
  axiosInstance
    .post("/AdminConroller/writeMessage")
    .then((response) => {
      dispatch(getAllMessageSuccess(response?.data));
    })
    .catch((err) => {
      console.log(err);
    });
};

//get message action
export const getMessageCustomerAsync = () => (dispatch) => {
  axiosInstance
    .get("AdminConroller/getCustumMessage")
    .then((response) => {
      dispatch(getAllMessageSuccess(response?.data));
    })
    .catch((err) => {
      console.log(err);
      // dispatch(updateBusinessFailed());
    });
};

// Write new message
export const AddNewMessageAsync = (msg) => async (dispatch) => {
  let data = {
    Message: msg,
  };
  dispatch(AddNewMsg());
  try {
    const response = await axiosInstance.post(
      "/AdminConroller/writeMessage",
      data
    );
    if (response) {
      dispatch(AddNewMsgSuccess(response?.data?.wroteMessage));
    }
  } catch (err) {
    dispatch(AddNewMsgFailed(err));
  }
};

// Edit message Action---
export const EditMessageAsync = (data) => async (dispatch) => {
  dispatch(EditMsg());
  try {
    const response = await axiosInstance.post(
      "/AdminConroller/UpdateCustomMsg",
      data
    );
    if (response) {
      dispatch(EditMsgSuccess(response));
      return response;
    }
  } catch (err) {
    dispatch(EditMsgFailed(err));
  }
};

//Get Customers Action
export const getSearchCustomerAsync = (data, recentUpdate) => (dispatch) => {
  console.log("respRRR", recentUpdate);
  if (data?.temp === 1) {
    dispatch(getAllTempCustomer());
  } else if (data?.isAdminApproved === 1) {
    dispatch(getAllsearchCustomer());
  } else {
    dispatch(getAllnewAccountVerifation());
  }
  axiosInstance
    .get("Login/GetCustomersForAdmin", {
      params: {
        name: data.name,
        location: data.location,
        email: data.email,
        username: data.userName,
        address: data.address,
        isAdminApproved: data.isAdminApproved,
        page: data?.page,
        perPage: data?.perPage,
        temp: data?.temp,
        // isSort: data?.isSort,
      },
    })
    .then((response) => {
      if (response.data) {
        console.log("resp", response);
        response.data.CurrentPage = data?.page;
        if (data?.temp === 1) {
          dispatch(getAllTempCustomerSuccess(response?.data));
        } else if (data?.isAdminApproved === 1) {
          dispatch(getAllsearchCustomerSuccess(response?.data));
        } else {
          if (recentUpdate === false) {
            dispatch(
              getAllnewAccountVerifationSuccess({ item: response?.data })
            );
          } else {
            dispatch(getAllnewAccountVerifationSuccess(response?.data));
          }
        }
      }
    })
    .catch((err) => {
      console.log("eroorrr", err);
      if (data?.temp === 1) {
        dispatch(getAllTempCustomerFailed());
      } else if (data.isAdminApproved === 1) {
        dispatch(getAllsearchCustomerFailed());
      } else {
        dispatch(getAllnewAccountVerifationFailed());
      }
      toast.error(err?.response?.data?.message);
    });
};

//Update Customer Action
export const updateCustomerAsync =
  (emailAddress, isAdminApproved) => (dispatch) => {
    dispatch(updateCustomer(emailAddress));

    axiosInstance
      .post("Login/UpdateCustomerProfile", {
        emailAddress,
        isAdminApproved,
      })
      .then((response) => {
        console.log(response);

        dispatch(updateCustomerSuccess(emailAddress));
      })
      .catch((err) => {
        console.log(err);

        dispatch(updateCustomerFailed());
      });
  };
//Delete Customer Action
export const deleteCustomerAsync = (data) => async (dispatch) => {
  // const data = data?.id;
  if (data?.userType === "customer") {
    dispatch(deleteCustomer(data?.CustomerId));
  } else {
    dispatch(deleteTempUser(data?.CustomerId));
  }
  try {
    const response = await axiosInstance.delete("/Login/DeleteCustomerUser/", {
      params: {
        CustomerId: data?.CustomerId,
      },
    });
    if (response) {
      console.log("rspppppp", response);
      if (response?.status === 200 || 201) {
        dispatch(deleteCustomerSuccess(data?.CustomerId));
        dispatch(deleteTempUserSuccess(data?.CustomerId));
        dispatch(deleteTempStatus(data?.CustomerId));
        toast.success("User Deleted Successfully!!");
        return true;
      } else {
        toast.error("User not Deleted !!");
      }
    }
  } catch (err) {
    console.log(err);
    dispatch(deleteCustomerFailed(err.response.data));
    dispatch(deleteTempUserFailed(err.response.data));
    return false;
  }
};

//Delete Business Action
export const deleteBusinessAsync = (id) => async (dispatch) => {
  dispatch(deleteBusiness());
  try {
    const response = await axiosInstance.delete(
      "BusisnessUser/DeleteBusinessUser",
      {
        params: {
          BusinessUserId: id,
        },
      }
    );
    if (response) {
      dispatch(deleteBusinessSuccess(id));
      if (response?.status === 200 || 201) {
        toast.success("User Deleted Successfully!!");
        return true;
      } else {
        toast.error("User not Deleted !!");
      }
    }
  } catch (error) {
    if (error?.response?.data?.statusCode === 400) {
      toast.error("User not Deleted !!");
    }
    dispatch(deleteBusinessFailed());
    return "error";
  }
};

//Create Temp Business Action
// export const createBusinessAsync = (data) => (dispatch) => {
//   dispatch(createBusiness());

//   axiosInstance
//     .post("Login/CreateTempBusiness", data)
//     .then((response) => {
//       console.log(response);
//       dispatch(createBusinessSuccess(response.data));
//     })
//     .catch((err) => {
//       console.log(err);
//       dispatch(createBusinessFailed());
//     });
// };

export const createTempAccounts = (formData, navigate) => async (dispatch) => {
  dispatch(createCustomer());
  try {
    const response = await axiosInstance.post("/upload/createUser", formData);
    dispatch(createCustomerSuccess(response.data?.user));
    if (response?.statusText === 200 || 201) {
      Swal.fire({
        title: "Success",
        text: "Account Created Successfully",
        icon: "success",
        confirmButtonText: "ok",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(-1);
        }
      });
      return response;
    }
  } catch (err) {
    console.log("errrrrr", err);
    await Swal.fire({
      title: "failed",
      text: err?.response?.data?.message,
      icon: "error",
      confirmButtonText: "ok",
    });
    dispatch(createCustomerFailed(err));
    throw err;
  }
};

// Update status action ---
export const UpdateStatusAsync = (data) => async (dispatch) => {
  const { id, isReview, isAdminApproved, accountType, PrevVeried } = data;
  const resData = { id, isReview };
  if (data?.accountType === "customer") {
    dispatch(VerifyUser());
  } else if (data?.accountType === "business") {
    dispatch(VerifyBusinessUser());
  }
  try {
    const response = await axiosInstance.post(
      "/upload/UpdateUserStatusById",
      data
    );
    if (isAdminApproved || PrevVeried) {
      if (accountType === "customer") {
        dispatch(VerifyUserSuccess(data));
      } else if (accountType === "business") {
        dispatch(updateBusinessSuccess(data));
      }
    } else {
      if (accountType === "customer") {
        dispatch(VerifyUserReviewSuccess(resData));
      } else if (accountType === "business") {
        dispatch(updateBusinessReviewSuccess(resData));
      }
    }
    return response;
  } catch (error) {
    if (data.accountType === "customer") {
      dispatch(VerifyUserFailed());
    } else if (data.accountType === "business") {
      dispatch(updateBusinessFailed());
    }

    return error;
  }
};

export const UpdateTempPassAsync = (data) => async (dispatch) => {
  dispatch(UpdateTempPass());
  try {
    const response = await axiosInstance.post("/Login/changePassword", data);
    if (response) {
      dispatch(UpdateTempPassSuccess(response));
      if (response.data.success === true) {
        Swal.fire({
          icon: "success",
          title: "Password updated",
        });
        return response;
      }
    }
  } catch (error) {
    console.log("error", error);
    dispatch(UpdateTempPassFailed());
    if (error?.response?.data?.statusCode !== 200 || 201) {
      Swal.fire({
        icon: "error",
        title:
          error?.response?.statusText === "Conflict"
            ? "Somethig went wrong ! try after some time "
            : error?.response?.data?.message,
      });
      return error;
    }
  }
};

export const UpdateProfileAsync = (fromData, navigate) => (dispatch) => {
  dispatch(UpdateTheProfile());
  axiosInstance
    .post("/upload/UpdateUser", fromData)
    .then((response) => {
      console.log("resp,,,", response);
      dispatch(UpdateProfileSuccess(response.data));
      if (response?.status === 200 || 201) {
        Swal.fire({
          icon: "success",
          text: "Profile Updated",
        });
        navigate(-2);
      } else {
        Swal.fire({
          icon: "error",
          text: "Failed",
        });
        // navigate(-2);
      }
    })
    .catch((err) => {
      console.log("errorrrrrr", err);
      Swal.fire({
        icon: "error",
        text: err?.response?.data?.message
          ? err?.response?.data?.message
          : err?.response?.data?.message[1],
      });
      dispatch(UpdateProfileFailed(err?.response?.data?.message[1]));
    });
};

// update message status action;

export const UpdatemessageStatusAsync = (questionId) => async (dispatch) => {
  dispatch(Update_messsage_Status());
  try {
    const response = await axiosInstance.post("/MarkAsSeen", {
      params: {
        id: questionId,
        Type: "SeenQuestion",
      },
    });
    dispatch(Update_messsage_Status_Success(response?.data));
  } catch (err) {
    dispatch(Update_messsage_Status_Failed(err?.response));
  }
};
export const {
  getAllsearchBussiness,
  getAllsearchBussinessSuccess,
  getAllsearchBussinessFailed,
  getAllsearchCustomer,
  getAllsearchCustomerSuccess,
  getAllsearchCustomerFailed,
  getAllnewAccountVerifation,
  getAllnewAccountVerifationSuccess,
  getAllnewAccountVerifationFailed,
  getAllBussinessLicenseVerification,
  getAllBussinessLicenseVerificationData,
  getAllBussinessLicenseVerification_filtered_Data,
  getAllBussinessLicenseVerificationFailed,
  updateCustomer,
  updateCustomerSuccess,
  updateCustomerFailed,
  updateBusiness,
  updateBusinessSuccess,
  updateBusinessReviewSuccess,
  updateBusinessFailed,
  getAllTempBusiness,
  getAllTempBusinessSuccess,
  getAllTempBusinessFailed,
  getAllTempCustomer,
  getAllTempCustomerSuccess,
  getAllMessageSuccess,
  getAllTempCustomerFailed,
  deleteCustomer,
  deleteCustomerSuccess,
  deleteCustomerFailed,
  deleteBusiness,
  deleteBusinessSuccess,
  deleteBusinessFailed,
  createBusiness,
  createBusinessSuccess,
  createBusinessFailed,
  createCustomer,
  createCustomerSuccess,
  createCustomerFailed,
  deleteTempUserSuccess,
  deleteTempUser,
  deleteTempUserFailed,
  deleteTempStatus,
  selectedUserDetails,
  UserAccountType,
  getAllProBusiness,
  getAllProBusinessSuccess,
  getAllProBusinessFailed,
  VerifyUser,
  VerifyBusinessUser,
  VerifyUserSuccess,
  VerifyUserReviewSuccess,
  VerifyUserFailed,
  UpdateTempPass,
  UpdateTempPassSuccess,
  UpdateTempPassFailed,
  UpdateTheProfile,
  UpdateProfileSuccess,
  UpdateProfileFailed,
  SelectedFilterLocation,
  SelectedFilterToDate,
  SelectedFilterFromDate,
  setSelectedOptions,
  setSelectedOption,
  getAllAdminSuccess,
  Send_Message,
  Send_Message_Success,
  Send_Message_Failed,
  Update_messsage_Status,
  Update_messsage_Status_Success,
  Update_messsage_Status_Failed,
  AddNewMsg,
  AddNewMsgSuccess,
  AddNewMsgFailed,
  EditMsg,
  EditMsgSuccess,
  EditMsgFailed,
  UpdateIsLastMsgRead,
} = getUsersSlice.actions;
export default getUsersSlice.reducer;
