import React, { useState, useEffect } from "react";
import "./CreateTempAccount.scss";
import Arrow from "../../../assets/icons/arrow-left.svg";
import Cross from "../../../assets/icons/cross-blue.svg";
import Save from "../../../assets/icons/save.svg";
import { useNavigate, useLocation } from "react-router-dom";
import Container from "../../../components/HOC/Container";
import AdminIcon from "../../../assets/icons/admin-pic-icon.svg";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Switch from "react-switch";
import { useSelector, useDispatch } from "react-redux";
import {
  getCategoryAsync,
  getSubCategoryAsync,
} from "../../../redux/categorySlice";
import { getAllLocationAsync } from "../../../redux/announcementsSlice";
import { createTempAccounts } from "../../../redux/usersSlice";
import Loader from "../../../components/Loader";
import Swal from "sweetalert2";
import Modal from "@mui/material/Modal";
import Dialog from "@mui/material/Dialog";
import { FormHelperText } from "@mui/material";
// import Item from "antd/es/list/Item";
const CreateTemporaryAccount = () => {
  const { categoryData, categoryOpenedDetail, subCategoryData } = useSelector(
    (state) => state.category
  );
  const {
    selectedAccountType,
    createCustomerLoading,
    createBusinessUserLoading,
  } = useSelector((state) => state.users);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Business Data----
  const [checked, setchecked] = useState(false);
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [serviceCategoryId, setServiceCategoryId] = useState("");
  const [serviceSubCategoryId, setServiceSubCategoryId] = useState("");
  const [userId, setUserId] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [state, setState] = useState([]);
  const [citiState, setCitiState] = useState(false);
  const [openCitiesModal, setOpenCitiesModal] = useState(false);
  const [selectedCities, setSelectedCities] = useState([]);
  const [file, setFile] = useState();
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedLocations, setSelectedLocations] = useState([]);
  const { AllCitiesData } = useSelector((state) => state.announcement);
  const [error, setError] = useState(false);
  const [locationChange, setLocationChange] = useState(false);
  const [selectedState, setSelectedState] = useState("");
  const handleChangeNumber = (e) => {
    const value = e.target.value;
    if (value.length !== 10) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const handleChange = () => {
    setchecked(!checked);
  };
  useEffect(() => {
    dispatch(getCategoryAsync("", categoryOpenedDetail.id));
  }, []);
  const getSeacrhData = (item) => {
    const categoryId = item?.id;
    setServiceCategoryId(categoryId);
    dispatch(getSubCategoryAsync("", categoryId));
  };
  const SubCategoryId = (item) => {
    setServiceSubCategoryId(item?.id);
  };
  const SelectImage = (e) => {
    setSelectedImage(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
  };
  const PasswordChange = (e) => {
    setPassword(e.target.value);
    console.log("console ", e.target.value);
  };
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };
  const validtion = () => {
    if (userId === "") {
      Swal.fire({
        title: "Error!",
        text: "UserId is required",
        icon: "error",
        confirmButtonText: "ok",
      });
      return false;
    } else if (selectedAccountType === "CUSTOMER_USER" && fullName === "") {
      Swal.fire({
        title: "Error!",
        text: "FullName is required",
        icon: "error",
        confirmButtonText: "ok",
      });
      return false;
      // }
      // else if (selectedAccountType === "CUSTOMER_USER" && phoneNumber === "") {
      //   Swal.fire({
      //     title: "Error!",
      //     text: "Phone number is required",
      //     icon: "error",
      //     confirmButtonText: "ok",
      //   });
      //   return false;
      // } else if (selectedAccountType === "CUSTOMER_USER" && emailAddress === "") {
      //   Swal.fire({
      //     title: "Error!",
      //     text: "Email is required",
      //     icon: "error",
      //     confirmButtonText: "ok",
      //   });
      //   return false;
      // } else if (selectedAccountType === "CUSTOMER_USER" && location === "") {
      //   Swal.fire({
      //     title: "Error!",
      //     text: "Location is required",
      //     icon: "error",
      //     confirmButtonText: "ok",
      //   });
      //   return false;
    } else if (selectedAccountType === "BUSSINESS_USER" && !serviceCategoryId) {
      Swal.fire({
        title: "Error!",
        text: "Category and Sub-category is required",
        icon: "error",
        confirmButtonText: "ok",
      });
      return false;
    } else if (emailAddress?.length > 0 && !validateEmail(emailAddress)) {
      Swal.fire({
        title: "Error!",
        text: "Invalid email address",
        icon: "error",
        confirmButtonText: "OK",
      });
      return false;
    }
    return true;
  };
  const Reset = () => {
    setFullName("");
    setPhoneNumber("");
    setUserId("");
    setEmailAddress("");
    setBusinessAddress("");
    setServiceCategoryId("");
    setServiceSubCategoryId("");
    setchecked(false);
    setFile("");
    setPassword("");
    setState([]);
    setZipCode("");
    setError(false);
  };
  const SaveData = async (e) => {
    const locationArray = selectedLocations?.map((itm) => {
      let data = itm?.city;
      return data;
    });
    const formData = new FormData();
    formData.append("files", file);
    if (validtion()) {
      if (selectedAccountType === "BUSSINESS_USER") {
        formData.append(
          "metadata",
          JSON.stringify({
            fullName: fullName,
            phoneNumber: phoneNumber,
            userId: userId,
            serviceCategoryId: serviceCategoryId,
            serviceSubCategoryId: [serviceSubCategoryId],
            isAdminApproved: 1,
            emailAddress: emailAddress,
            streetAddress: businessAddress,
            locationArr: locationArray,
            zipCode: zipCode,
            state: state,
            isVerified: 1,
            password: password,
            accountType: "business",
            AverageRating: 0,
            demo: 1,
            status: checked,
            No_Of_Completed_Job: 0,
            Avg_Rating: 0,
          })
        );
        try {
          await dispatch(createTempAccounts(formData, navigate));
          Reset(); // Call Reset only if dispatch is successful
        } catch (error) {
          console.error("Error creating account:", error);
        }
        // dispatch(createTempAccounts(formData, navigate));
      } else {
        formData.append(
          "metadata",
          JSON.stringify({
            fullName: fullName,
            emailAddress: emailAddress,
            streetAddress: businessAddress,
            userId: userId,
            locationArr: locationArray,
            state: state,
            password: password,
            accountType: "customer",
            role: "customer",
            isAdminApproved: 1,
            tempAccount: 1,
            isVerified: 1,
            status: 1,
            isCompleted: 1,
            No_Of_Completed_Job: 0,
            Avg_Rating: 0,
          })
        );
        try {
          await dispatch(createTempAccounts(formData, navigate));
          Reset(); // Call Reset only if dispatch is successful
        } catch (error) {
          console.error("Error creating account:", error);
        }
      }
      Reset();
    }
  };
  const GetingLocation = (item) => {
    setSelectedState(item);
    setCitiState(false);
    const citiesToRemove = item?.usCanadaCitiesMasterTbl.filter((cityObj) => {
      const cityName = cityObj.city;
      return (
        cityName.substring(0, 2).toUpperCase() !== cityName.substring(0, 2)
      );
    });
    setSelectedCities(citiesToRemove);
    setOpenCitiesModal(true);
  };
  const LoactionData = (item) => {
    setLocationChange(true);
    const existingLocationIndex = selectedLocations.findIndex(
      (loc) => loc.city === item.city
    );

    if (existingLocationIndex !== -1) {
      // Location already selected, remove it
      const updatedLocations = selectedLocations.filter(
        (loc) => loc.city !== item.city
      );
      setSelectedLocations(updatedLocations);
    } else {
      // Location not selected, add it
      setSelectedLocations([...selectedLocations, item]);
    }
    setState((prevState) => {
      if (!prevState.includes(selectedState?.state)) {
        return [...prevState, selectedState?.state];
      }
      return prevState;
    });
    setOpenCitiesModal(false);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      setSelectedLocations((prevState) => prevState?.slice(0, -1));
      setState((prevState) => prevState?.slice(0, -1));
    }
  };
  // useEffect(() => {
  //   window.addEventListener("keydown", handleKeyDown);
  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);
  const GetAllLocation = () => {
    setCitiState(true);
    dispatch(getAllLocationAsync());
  };
  return (
    <Container>
      {createBusinessUserLoading || createCustomerLoading ? <Loader /> : null}

      <div className="CreateTempAccountMainContainer">
        <div className="UserProfileMainContainerHeader">
          <div className="HeadingAndBack">
            <button onClick={() => navigate(-1)}>
              <img src={Arrow} alt="Arrow" className="h-6" />
            </button>

            {selectedAccountType === "CUSTOMER_USER" ? (
              <h1>Add Temporary Customer Account</h1>
            ) : (
              <h1>Add Temporary Business Account</h1>
            )}
          </div>
          <div className="Close_btn_And_Edit_btn">
            <button onClick={() => navigate(-1)} className="cancelBtn ">
              <img src={Cross} alt="Cancel" />
              <span>Close</span>
            </button>
            <button onClick={SaveData} className="saveBtn ">
              <img src={Save} alt="Save" />
              <span>Save</span>
            </button>
          </div>
        </div>
        <div className="BottomCreateTempAccount">
          <div className="AddCustomerDetails_Container">
            <div className="upload-photo-view">
              <div className="ImageContainer">
                {selectedImage === "" ? (
                  <img src={AdminIcon} alt="upload " />
                ) : (
                  <img src={selectedImage} alt="upload " />
                )}
              </div>
              <div className="BTN_And_Condition">
                <div className=" UploadButtonContainer ">
                  <button className="upload-pic-btn">
                    <input
                      title="Upload Photo"
                      type="file"
                      onChange={SelectImage}
                    />
                  </button>
                  <button onClick={Reset} className="reset-btn ">
                    <span className="reset-text">Reset</span>
                  </button>
                </div>
                <p className="allowed-pic-details">
                  Allowed JPG, GIF or PNG. Max size of 800K
                </p>
              </div>
            </div>
            <div className="Common_details">
              <TextField
                className="Inputs"
                required
                id="outlined-required"
                label="UserId"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
              />
              <TextField
                className="Inputs"
                required
                id="outlined-required"
                label="Password"
                value={password}
                // security
                type="password"
                onChange={PasswordChange}
              />
            </div>
            <div className="Common_details">
              <TextField
                style={{
                  width:
                    selectedAccountType === "CUSTOMER_USER" ? "100%" : "48%",
                }}
                className="Inputs"
                required={
                  selectedAccountType === "CUSTOMER_USER" ? true : false
                }
                id="outlined-required"
                label={
                  selectedAccountType === "BUSSINESS_USER"
                    ? "Business Name"
                    : "Full Name"
                }
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
              {selectedAccountType === "BUSSINESS_USER" && (
                <TextField
                  className="Inputs"
                  required={
                    selectedAccountType === "CUSTOMER_USER" ? true : false
                  }
                  id="outlined-required"
                  label="Phone No"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  inputProps={{ maxLength: 10, minLength: 10 }}
                  error={error}
                  onChangeCapture={handleChangeNumber}
                />
              )}
            </div>

            {selectedAccountType === "BUSSINESS_USER" ? (
              <div className="Common_details">
                <TextField
                  className="Inputs"
                  id="outlined-select-currency"
                  required
                  select
                  label="Category"
                >
                  {categoryData.map((item, key) => (
                    <MenuItem
                      onClick={() => getSeacrhData(item)}
                      key={item.name}
                      value={item.name}
                      defaultValue=""
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  className="Inputs"
                  id="outlined-select-currency"
                  required
                  select
                  label="Sub-category"
                >
                  {subCategoryData.map((subItem, key) => (
                    <MenuItem
                      onClick={() => SubCategoryId(subItem)}
                      key={subItem.name}
                      value={subItem.name}
                      defaultValue=""
                    >
                      {subItem.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            ) : null}
            <div className="Common_details">
              <input
                type="text"
                // required={
                //   selectedAccountType === "CUSTOMER_USER" ? true : false
                // }

                placeholder="Location *"
                onClick={GetAllLocation}
                onKeyDown={handleKeyDown}
                value={selectedLocations
                  .map(
                    (loc) =>
                      loc.city.charAt(0).toUpperCase() + loc.city.slice(1)
                  )
                  .join(", ")}
                style={{
                  borderWidth: "1.5px",
                  borderRadius: "5px",
                  // textAlign: "center",
                  // marginLeft: "10px",
                  padding: "10px",
                  color: "black",
                  borderColor: "#D3D3D3",
                  width: "48%",
                }}
              />
              <Dialog
                PaperProps={{
                  sx: {
                    position: "fixed",
                    m: 0,
                    right: "center",
                    width: "50%",
                    height: "90%",
                    backgroundColor: "#fff",
                  },
                }}
                open={citiState}
                onClose={() => setCitiState(false)}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p style={{ display: "flex", margin: "0 auto" }}>
                    Select State
                  </p>
                  {AllCitiesData?.map((item, key) => (
                    <MenuItem
                      defaultValue=""
                      onClickCapture={() => GetingLocation(item)}
                    >
                      <p style={{ padding: "0.5rem 1rem" }}>{key + 1}.</p>
                      <span className="StateAndCityNAme">
                        {item?.shortNameOfState}
                      </span>
                    </MenuItem>
                  ))}
                </div>
              </Dialog>
              <Dialog
                PaperProps={{
                  sx: {
                    position: "fixed",
                    m: 0,
                    right: "center",
                    width: "50%",
                    height: "90%",
                    backgroundColor: "#fff",
                  },
                }}
                open={openCitiesModal}
                onClose={() => setOpenCitiesModal(false)}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p style={{ display: "flex", margin: "0 auto" }}>
                    Select City
                  </p>

                  {/* <span className='StateAndCityNAme'>
                                {selectedCities.city}
                            </span> */}

                  {selectedCities?.map((item, key) => (
                    <MenuItem
                      key={item.city}
                      value={item.city}
                      defaultValue=""
                      onClickCapture={() => LoactionData(item)}
                    >
                      <p style={{ padding: "0.5rem 1rem" }}>{key + 1}.</p>
                      <span className="StateAndCityNAme"> {item.city}</span>
                    </MenuItem>
                  ))}
                </div>
              </Dialog>

              <TextField
                className="Inputs"
                required={
                  selectedAccountType === "CUSTOMER_USER" ? true : false
                }
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
                label="Email Address"
              />
            </div>
            {selectedAccountType === "CUSTOMER_USER" && (
              <div className="Common_details">
                <TextField
                  className="Inputs"
                  id="outlined-required"
                  label="State"
                  value={state}
                  type="text"
                  disabled
                />
              </div>
            )}
            {selectedAccountType === "BUSSINESS_USER" && (
              <div className="Common_details">
                <TextField
                  className="Inputs"
                  id="outlined-required"
                  label="State"
                  value={state}
                  type="text"
                  disabled
                />
                <TextField
                  className="Inputs"
                  id="outlined-required"
                  label="Zip-Code"
                  value={zipCode}
                  type="text"
                  onChange={(e) => {
                    const newValue = e.target.value;
                    if (/^\d{0,5}$/.test(newValue)) {
                      setZipCode(newValue);
                    }
                  }}
                />
              </div>
            )}
            <div className="Common_details">
              <TextField
                className="TextArea"
                id="outlined-required"
                label="Street-Address"
                multiline
                rows={3}
                value={businessAddress}
                onChange={(e) => setBusinessAddress(e.target.value)}
              />
            </div>
            <div className="Status">
              <p>Status</p>
              <Switch
                className="SwitchBTN"
                onChange={handleChange}
                checked={checked}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor="#38AB7C"
              />
              {checked === true ? <p>Active</p> : <p>Not Active</p>}
            </div>
          </div>
        </div>
      </div>
      {/* ) : (
        <Loader />
      )} */}
    </Container>
  );
};

export default CreateTemporaryAccount;
