import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../helper/axiosInstance";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
const initialState = {
  userType: "UNREGISTERED",
  userDetails: "",
  loginLoading: false,
  verifyAdminLoading: false,
  sessionTokenAdmin: "",
  adminData: [],
  logoutData: [],
  CSVVerificationAccountType: "",
  specialLoginLoading: false,
  specialLoginFailed: "",
  resendEamilLoading: false,
  resendEmailData: [],
  resendEmailError: "",
  currentSideBarStatus:true
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state) => {
      state.loginLoading = true;
    },
    loginMasterSuccess: (state, action) => {
      state.userType = action.payload.accountType;
      state.userDetails = action.payload;
      state.loginLoading = false;
      state.specialLoginLoading = false;
    },
    loginAdminSuccess: (state, action) => {
      console.log("token", action.payload);
      state.sessionTokenAdmin = action?.payload;
      state.loginLoading = false;
    },
    verifyAdmin: (state) => {
      state.verifyAdminLoading = true;
    },
    verifyAdminSuccess: (state, action) => {
      state.userDetails = action.payload;
      state.adminData = action.payload;
      state.userType = "admin";
      state.verifyAdminLoading = false;
    },
    verifyAdminFailed: (state) => {
      state.verifyAdminLoading = false;
    },
 
    loginAdminLocalSuccess: (state, action) => {
      state.userDetails = action.payload;
      state.loginLoading = false;
      state.userType = action.payload.accountType;
    },
    loginFailed: (state) => {
      state.loginLoading = false;
    },

    logout: (state) => {
      state.userType = "UNREGISTERED";
    },
    logoutSuccess: (state, action) => {
      console.log(action.payload);
      state.logoutData = action.payload;
      sessionStorage.removeItem("userLoggedDetails");
      sessionStorage.removeItem("userLoggedToken");
      sessionStorage.clear();
      state.userType = "UNREGISTERED";
    },
    logoutFailed: (state) => {
      // state.userType = "UNREGISTERED";
    },
    emptyAuthState: (state) => {
      console.log("empty state");
      state.userType = "UNREGISTERED";
      state.userDetails = "";
      state.loginLoading = false;
      state.verifyAdminLoading = false;
      state.sessionTokenAdmin = "";
    },
    recognizeAccountType: (state, action) => {
      // console.log(action.payload);
      state.CSVVerificationAccountType = action.payload;
    },

    LoginSpecial: (state) => {
      state.specialLoginLoading = true;
    },
    LoginSpecialSuccess: (state, action) => {
      state.specialLoginLoading = false;
      state.adminData = action.payload;
      console.log("speccial login admin data........", action.payload);
    },
    LoginSpecialFailed: (state, action) => {
      state.specialLoginLoading = false;
      state.specialLoginFailed = action.payload;
    },

    ResendEmail: (state) => {
      state.resendEamilLoading = true;
    },
    ResendSuccessfully: (state, action) => {
      state.resendEamilLoading = false;
      state.resendEmailData = action.payload;
    },
    ResendError: (state, action) => {
      state.resendEamilLoading = false;
      state.resendEmailError = action.payload;
    },
    updateidebarStatus: (state, action) => {
      state.currentSideBarStatus = action.payload;
    },
  },
});

export const loginAsync = (data, remember) => async (dispatch) => {
  dispatch(login());
  const MasterAdminData = localStorage.getItem("MaUser");
  try {
    const response = await axiosInstance.post("/upload/userLogin", data);
    if (response) {
      if (response.data?.user?.accountType === "masteradmin") {
        const userData = {
          ...response?.data?.user,
          remember,
        };

        var user = {
          userId: data.userId,
          password: data.password,
        };
        var MaDetails = JSON.stringify(user);
        // Set the user object in localStorage
        localStorage.setItem("MaUser", MaDetails);
        dispatch(loginMasterSuccess(userData));
        sessionStorage.setItem(
          "userLoggedDetails",
          JSON.stringify(response.data?.user)
        );
        sessionStorage.setItem(
          "userLoggedToken",
          JSON.stringify(response.data?.token)
        );

        toast.success(MasterAdminData ? "Back to master admin" : "Login Successful!!");
        return "MASTERADMIN";
      } else {
        console.log("adminn login reponse data", response.data.user);
        dispatch(loginAdminSuccess(response?.data?.sessionToken));
        sessionStorage.setItem(
          "userLoggedDetails",
          JSON.stringify(response?.data?.user)
        );
        sessionStorage.setItem(
          "userLoggedToken",
          JSON.stringify(response.data?.token)
        );
        return response?.data?.user;
      }
    }
  } catch (error) {
    dispatch(loginFailed());

    toast.error(
      error?.response?.data?.message
        ? error.response.data.message
        : "Login failed!!"
    );
    console.log(error, error?.response?.data.message);

    return "error";
  }
};
// SpecialAdminLogin----------

export const SpecialAdminLogin = (data, remember) => async (dispatch) => {
  dispatch(LoginSpecial());
  try {
    const response = await axiosInstance.post(
      "/AdminConroller/specialAdminLogin",
      data
    );
    if (response) {
      dispatch(verifyAdminSuccess(response.data?.data));
      const token = response.data.tokenStr;
      sessionStorage.clear();
      sessionStorage.setItem(
        "userLoggedDetails",
        JSON.stringify(response.data?.data)
      );
      sessionStorage.setItem("userLoggedToken", JSON.stringify(token));
      toast.success("Login Successful!!");
    }
  } catch (error) {
    dispatch(LoginSpecialFailed(error));
    toast.error(
      error?.response?.data?.message
        ? error.response.data.message
        : "Login failed!!"
    );
    console.log(error, error?.response?.data.message);

    return "error";
  }
};

export const sessionStorageLoginAsync = (data) => async (dispatch) => {
  console.log("+++++", data);

  if (data) {
    if (data.accountType === "MASTERADMIN") {
      dispatch(loginMasterSuccess(data));
    } else if (data.accountType === "ADMIN") {
      dispatch(loginAdminLocalSuccess(data));
    }
  }
};

export const verifyAdminAsync = (data) => async (dispatch) => {
  dispatch(verifyAdmin());
  console.log(data);
  try {
    const response = await axiosInstance.post("AdminConroller/VerifyOtp", data);
    if (response) {
      dispatch(verifyAdminSuccess(response?.data));
      console.log("resp.............", response);
      sessionStorage.setItem(
        "userLoggedDetails",
        JSON.stringify(response.data)
      );
      sessionStorage.setItem(
        "userLoggedToken",
        JSON.stringify(response.data.token)
      );
      toast.success("Login Successful!!");
    }
  } catch (error) {
    dispatch(verifyAdminFailed());

    toast.error(
      error?.response?.data?.message
        ? error.response.data.message
        : "Login failed!!"
    );
    console.log(error.response.data.message);
    return "error";
  }
};


export const logoutUserAsync = (data) => async (dispatch) => {
  if (!data.isSwitch) {
    dispatch(logout());
  }
  try {
    const response = await axiosInstance.post(
      "/AdminConroller/LogoutUser",
      data
    );
    if (!data.isSwitch) {
      if (response.status === 200) {
        dispatch(logoutSuccess(response.status));
        // localStorage.clear();
        return response
      } else {
        dispatch(logoutFailed());
        Swal.fire("Sorry", " Unable to Logout", "error");
      }
    }
  } catch (err) {
    dispatch(logoutFailed());
    // Swal.fire("Sorry", " Unable to Logout", "error");
  }
};

export const ResendEamilAsync = (data) => async (dispatch) => {
  dispatch(ResendEmail());
  try {
    const response = await axiosInstance.post(
      "/upload/resendMailForIsVerifiedTrue",
      data
    );
    if (response) {
      console.log("repo", response);
      dispatch(ResendSuccessfully(response.data));
      return response.data;
    }
  } catch (error) {
    console.log(error);
    dispatch(ResendError());
  }
};




export const {
  login,
  loginMasterSuccess,
  loginAdminSuccess,
  loginAdminLocalSuccess,
  verifyAdmin,
  verifyAdminSuccess,
  verifyAdminFailed,
  loginFailed,
  logout,
  emptyAuthState,
  logoutSuccess,
  logoutFailed,
  recognizeAccountType,
  LoginSpecial,
  LoginSpecialSuccess,
  LoginSpecialFailed,
  GetStatics,
  GetStaticsSuccess,
  GetStaticsFailed,
  ResendEmail,
  ResendSuccessfully,
  ResendError,
  updateidebarStatus
} = authSlice.actions;

export default authSlice.reducer;
